import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import { PATHWAYS_API_URL } from "../../constants";
import AdminPathwayList from "./PathwayList";
import TalentInsightsHeader from "./Header";


const CareerPathways = (props) => {
    const token = localStorage.getItem('accessToken');
    let [pathways, setPathways] = useState([]);
    let [pathway_search, setPathwaySearch] = useState('');

    const handlePathwaySearch = (event) => {
        setPathwaySearch(event.target.value);
        getFilteredPathways(event.target.value);
    };

    const getPathways = () => {
        axios.get(PATHWAYS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setPathways(res.data));
    };

    const getFilteredPathways = (pathway_search) => {
        axios.get(PATHWAYS_API_URL + '?name=' + pathway_search, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setPathways(res.data));
    };

    useEffect(() => getPathways(), []);

    return (
    <Container className="Main MainAdminFullWidth MainWithHero">
        <div className="Centralizer White">
        <TalentInsightsHeader 
            current_user={props.current_user}
        />
        <Row>
            <Col>
                <div className="Box DarkPurple TalentInsightsBox">
                    <h2>
                        {props.current_user?.current_language_code == 'en' ? 'Career Pathways' : ''}
                        {props.current_user?.current_language_code == 'fi' ? 'Urapolut' : ''}
                        {props.current_user?.current_language_code == 'de' ? 'Career Pathways' : ''}                        
                    </h2>
                    <div className="RecruitmentSearch AdminFilter">
                        <input className="TextInput" placeholder="Search" value={pathway_search} onChange={handlePathwaySearch}/>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
          <Col>
            <AdminPathwayList
                pathways={pathways}
                pathway_search={pathway_search}
                current_user={props.current_user}
            />
          </Col>
        </Row>
      </div>
      </Container>
    );
}

export default CareerPathways;
