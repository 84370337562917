import { faDashboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const CampaignsHeader = (props) => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    return (
        <>
        <h1>Campaign Performance</h1>
        <Row>
            <Col>
                <div className="AdminFilters">
                    <ul className="StatusPillFilters">
                        <li className="NavItem">
                            <Link to="/campaigns" className={!splitLocation[2] ? "NavLink Active" : "NavLink"}>
                                <FontAwesomeIcon icon={faDashboard} />
                            </Link>
                        </li>
                        {/* <li className="NavItem">
                            <Link to="/campaigns/performance" className={splitLocation[2] == 'performance' ? "NavLink Active" : "NavLink"}>
                                Campaign Performance
                            </Link>
                        </li> */}
                        <li className="NavItem">
                            <Link to="/campaigns/campaigns" className={splitLocation[2] == 'campaigns' ? "NavLink Active" : "NavLink"}>
                                Campaigns
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/campaigns/campaign-success" className={splitLocation[2] == 'campaign-success' ? "NavLink Active" : "NavLink"}>
                                Campaign Success
                            </Link>
                        </li>
                        {/* <li className="NavItem">
                            <Link to="/campaigns/funnel-analytics" className={splitLocation[2] == 'funnel-analytics' ? "NavLink Active" : "NavLink"}>
                                Funnel Analytics
                            </Link>
                        </li> */}
                        <li className="NavItem">
                            <Link to="/campaigns/candidates" className={splitLocation[2] == 'candidates' ? "NavLink Active" : "NavLink"}>
                                Candidates
                            </Link>
                        </li>
                        {/* <li className="NavItem">
                            <Link to="/campaigns/ad-spending" className={splitLocation[2] == 'ad-spending' ? "NavLink Active" : "NavLink"}>
                                Ad Spending
                            </Link>
                        </li> */}
                        {/* <li className="NavItem">
                            <Link to="/campaigns/sources" className={splitLocation[2] == 'sources' ? "NavLink Active" : "NavLink"}>
                                Sources
                            </Link>
                        </li> */}
                        {/* <li className="NavItem">
                            <Link to="/campaigns/countries" className={splitLocation[2] == 'countries' ? "NavLink Active" : "NavLink"}>
                                Countries
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/campaigns/cities" className={splitLocation[2] == 'cities' ? "NavLink Active" : "NavLink"}>
                                Cities
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </Col>
        </Row>
        </>
);
}

export default CampaignsHeader;
