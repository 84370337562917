// React
import React, { Component, useState, useContext } from "react";
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import { useLocation } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

// External
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faBriefcase, faBullseye, faChartLine, faCircle, faHandshake, faHome, faMoneyBill, faRobot, faUserDoctor, faUsers } from '@fortawesome/free-solid-svg-icons'

// Components
import MeridienLink from "./Meridien/Link";
import MeridienLogo from "./Meridien/Logo";
import MeridienAdminLink from "./Meridien/Admin";
import MeridienProfile from "./Meridien/Profile";
import MeridienCompanySwitcher from "./Meridien/Company";
import Dropdown from "./Dropdown";

const Meridien = (props) => {
    const { user, logoutUser } = useContext(AuthContext);
    const [dropdownVisible, setDropdown] = useState(false);

    const toggleDropdown = () => {
        setDropdown(prevState => ({ dropdownVisible: !prevState.dropdownVisible }));
    };

    const changeCurrentCompany = (new_current_company_id) => {
        props.changeCurrentCompany(new_current_company_id);
    };

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const current_user = props.current_user;

    return (
        <div className="Meridien">
            <ul>
                <MeridienLogo darkMode={props.darkMode}/>
                <MeridienLink to="/dashboard" title="Dashboard" icon={faHome} classes={splitLocation[1] === "dashboard" ? "NavLink Active" : "NavLink"}/>
                <MeridienLink to="/talent-insights" title="Talent Insights AI" icon={faUserDoctor} classes={splitLocation[1] === "talent-insights" ? "NavLink Active" : "NavLink"}/>
                <MeridienLink to="/growth" title="Growth" icon={faChartLine} classes={splitLocation[1] === "growth" ? "NavLink Active" : "NavLink"}/>
                {current_user?.user == 1 ? (
                <>
                {/* <MeridienLink to="/customers" title="Customers" icon={faUsers} classes={splitLocation[1] === "customers" ? "NavLink Active" : "NavLink"}/> */}
                {/* <MeridienLink to="/hire" title="Hiring" icon={faBriefcase} classes={splitLocation[1] === "hire" ? "NavLink Active" : "NavLink"}/> */}
                {/* <MeridienLink to="/finance" title="Finance" icon={faMoneyBill} classes={splitLocation[1] === "finance" ? "NavLink Active" : "NavLink"}/> */}
                </>
                ) : ('')}
                <MeridienLink to="/campaigns" title="Campaign Performance" icon={faBullseye} classes={splitLocation[1] === "campaigns" ? "NavLink Active" : "NavLink"}/>
                {[1, 4, 210, 254, 265].includes(current_user?.user) ? (
                <>
                <MeridienLink to="/ai-incubator" title="AI Incubator" icon={faRobot} classes={splitLocation[1] === "ai-incubator" ? "NavLink Active" : "NavLink"}/>
                </>
                ) : ('')}
            </ul>
        </div>
    )
}

export default Meridien;
