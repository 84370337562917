import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Row, Table, Card } from "reactstrap";
import { NumericFormat } from 'react-number-format';

import { Chart } from 'primereact/chart';

import axios from "axios";
import { GIANT_GENDER_API_URL } from "../../../constants";
import GiantWorkExperience from "./WorkExperience";
import GiantEducationLevel from "./EducationLevel";
import GiantGender from "./Gender";
import GiantAgeDistribution from "./AgeDistribution";
import GiantPullFactors from "./PullFactors";
import GiantJobBenefits from "./JobBenefits";
import GiantAspectsNegotiation from "./AspectsNegotiation";


const GiantAppreciation = (props) => {
    return (
    <>
        <Row>
            <Col sm="6">
                <GiantPullFactors group={props.group} />
            </Col>
            <Col sm="6">
                <GiantJobBenefits group={props.group} />
            </Col>
            <Col sm="6">
                <GiantAspectsNegotiation group={props.group} />
            </Col>
        </Row>
    </>
);
}

export default GiantAppreciation;
