import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import Moment from 'react-moment';

import { Knob } from 'primereact/knob';
import { ProgressBar } from 'primereact/progressbar';

import axios from "axios";
import { 
    TARGET_ADS_API_URL,
    TARGET_CAMPAIGNS_API_URL,
    POSTHOG_UNIQUE_VISITORS_API_URL
 } from "../../constants";
import TalentInsightsHeader from "./Header";
import CampaignsHeader from "./Header";
import FunnelConversion from "./Campaign/PostHog";
import CampaignAd from "./Campaign/Ad";
import { Skeleton } from "primereact/skeleton";
import CampaignAdMeta from "./Campaign/AdMeta";
import CampaignPostHog from "./Campaign/PostHog";

const Campaign = (props) => {
    const token = localStorage.getItem('accessToken');
    let [campaign, setCampaign] = useState();
    let [ads, setAds] = useState([]);
    let [posthogFunnelData, setPosthogFunnelData] = useState({});

    let url = window.location.pathname
    let url_components = url.split('/')
    let campaign_id = url_components[4]

    const getCampaign = () => {
        axios.get(TARGET_CAMPAIGNS_API_URL + campaign_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setCampaign(response.data)
        });
    };

    const getAds = () => {
        axios.get(TARGET_CAMPAIGNS_API_URL + campaign_id + '/ads/', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setAds(response.data)
        });
    };

    const getPosthogFunnelData = () => {
        axios.get(POSTHOG_UNIQUE_VISITORS_API_URL + campaign_id +'/', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setPosthogFunnelData(response.data)
        });
    };

    // const getFilteredGroups = () => {
    //     let complete_url = GROUPS_API_URL + '?pathway=' + pathway_id
    //     axios.get(complete_url).then(res => setGroups(res.data));    
    // };


    useEffect(() => getPosthogFunnelData(), []);
    useEffect(() => getCampaign(), []);
    useEffect(() => getAds(), []);
    // useEffect(() => getFilteredGroups(), []);

    return (
        <Container className="Main MainAdminFullWidth MainWithHero">
            <div className="Centralizer">
                <CampaignsHeader 
                    current_user={props.current_user}
                />
                <Row>
                    <Col>
                        <div className="Box DarkPurple TalentInsightsBox">
                            <h2>
                                Campaign: {campaign?.job_name}
                            </h2>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="Content">
                <CampaignPostHog
                    posthogFunnelData={posthogFunnelData}
                />
          </div>
            <div className="Content">
                <Row className="Content">
                    <Col sm="3">
                        <div className="Box">
                            <h4>Campaign Basics</h4>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td width="50%">
                                            Funnel
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            Facebook ID
                                        </td>
                                        <td>
                                            {campaign?.facebook_id}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            Job ID
                                        </td>
                                        <td>
                                            {campaign?.job}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            Dates
                                        </td>
                                        <td>
                                            <Moment format="DD/MM/YYYY" date={campaign?.starts_at} />
                                                - 
                                            <Moment format="DD/MM/YYYY" date={campaign?.ends_at} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col sm="3">
                        <div className="Box">
                            <h4>Job</h4>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td width="50%">
                                            Career Cluster
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            Career Pathway
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            Job Group (ESCO)
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col sm="3">
                        <div className="Box">
                            <h4>Ad Image</h4>
                            {ads?.map(ad => (
                                <CampaignAd 
                                    key={ad.id}
                                    ad={ad}
                                />
                                )
                            )}
                        </div>
                    </Col>
                    <Col sm="3">
                        <div className="Box">
                            <h4>Meta &amp; Sources</h4>
                            {ads?.map(ad => (
                                <CampaignAdMeta
                                    key={ad.id}
                                    ad={ad}
                                />
                                )
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
            <br/>
        </Container>
    );
};
export default Campaign;