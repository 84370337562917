import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table } from "reactstrap";
import { NumericFormat } from 'react-number-format';

import { Chart } from 'primereact/chart';

import axios from "axios";
import { GIANT_ASPECTS_NEGOTIATION_API_URL } from "../../../constants";


const GiantAspectsNegotiation = (props) => {
    const token = localStorage.getItem('accessToken');
    let [data, setData] = useState();

    const getData = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
        axios.get(GIANT_ASPECTS_NEGOTIATION_API_URL + group_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setData(response.data);
        });
    };

    useEffect(() => getData(), []);

    return (
        <div className="TalentInsightsContentBox">
            <h4 className="TalentInsightBoxCentered">Negotiation Aspects</h4>
            <Table className="">
                <thead className="TalentInsightsTableHead">
                    <tr>
                        <th>Label</th>
                        <th className="text-right">%</th>
                    </tr>
                </thead>
                <tbody>
                {!data || data?.length <= 0 ? (
                    <tr>
                        <td colSpan="2">
                            <b>Ops, no data here yet</b>
                        </td>
                    </tr>
                ) : (
                    data?.map(d => (
                    <tr key={d.id}>
                        <td width="50%">
                            {d.label}
                        </td>
                        <td width="50%" align="right">
                            <NumericFormat
                                value={d.value * 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={1}
                            /> %
                        </td>
                    </tr>
                    ))
                )}
                </tbody>
            </Table>
            <span className="TalentInsightsBoxDescription">
                The key aspects that candidates negotiate in a job application process.
                Data source Intelligence Group.
            </span>
        </div>
);
}

export default GiantAspectsNegotiation;
