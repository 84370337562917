import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { ORDER_DATA_API_URL } from "../../constants";

import { Chart } from 'primereact/chart';

import SalesHeader from "./Header";
import { ProgressSpinner } from "primereact/progressspinner";
import BoxSales from "./Boxes/Sales";
import BoxOrderCount from "./Boxes/OrderCount";

const Orders = (props) => {
    const token = localStorage.getItem('accessToken');
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    let [data, setData] = useState();
    let [dataLabels, setDataLabels] = useState();
    let [dataValues, setDataValues] = useState();
    const [error, setError] = useState(null);

    const getData = id => {
        axios.get(ORDER_DATA_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            setData(response.data);
            setDataLabels(response.data.map(obj => obj.time));
            setDataValues(response.data.map(obj => obj.value));
            setError(null); // Clear any previous errors
        })
    };

    useEffect(() => {
        if (dataLabels && dataValues) {
            const documentStyle = getComputedStyle(document.documentElement);
            const data = {
                labels: dataLabels,
                datasets: [
                    {
                        data: dataValues,
                        backgroundColor: ['#9a77ca'],
                        hoverBackgroundColor: ['#7854ac']
                    }
                ]
            };

            const options = {
                plugins: {
                    legend: {
                        display: false // This will hide the label (legend)
                    }
                }
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [dataLabels, dataValues]);

    // useEffect(() => getData(), []);

    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer">
            <SalesHeader
                current_user={props.current_user}
            />
            <Row>
                <Col >
                    <h2>
                        Orders
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col md="4" sm="12">
                    <BoxOrderCount highlight={true} link={true} time={'this-month'} />
                </Col>
                <Col md="4" sm="12">
                    <BoxOrderCount link={true} time={'today'} />
                </Col>
            </Row>
            {/* <Row className="ContentWhite">
                <Col>
                    {dataLabels && dataValues ? (
                        <Chart type="bar" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
                    ): (
                        <div className="text-center">
                            <ProgressSpinner />
                        </div>
                    )}
                </Col>
            </Row> */}
        </div>
      </Container>
    );
}

export default Orders;
