import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import CustomersHeader from "./Header";
import BoxCompanyCount from "./Boxes/CompanyCount";


const CustomersDashboard = (props) => {

    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer White">
        <CustomersHeader
            current_user={props.current_user}
        />
        <Row>
            <Col>
                <h2>
                    Customers
                </h2>
            </Col>
        </Row>
            <Row>
                <Col md="4" sm="12">
                    <BoxCompanyCount link={true} />
                </Col>
            </Row>
        </div>
      </Container>
    );
}

export default CustomersDashboard;
