import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import AISuiteHeader from "./Header";


const AdImageAI = (props) => {

    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer">
            <AISuiteHeader
                current_user={props.current_user}
            />
        </div>
        <div className="Content">
            <Row>
                <Col sm="12">
                    <div className="alert alert-info">
                        User: jobi Pass: illa007
                    </div>
                </Col>
            </Row>
        </div>
        <iframe src="https://experiments-jobilla-ad-generation.vercel.app/#01924bf7-8e23-7848-82fe-e0117aeb6696" width="100%" height="100%" className="iFrame" />
        {/* <div className="Content">
        </div> */}
      </Container>
    );
}

export default AdImageAI;
