import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import AISuiteHeader from "./Header";
import AIIncubatorJobTitle from "./JobDetails";
import AIIncubatorJobDetails from "./JobDetails";


const PredictAI = (props) => {
    const token = localStorage.getItem('accessToken');

    return (
        <div className="Content">
            <Row>
                <Col>
                    <div className="WelcomeContent">
                        Predict 
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default PredictAI;
