import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Chart } from 'primereact/chart';

import axios from "axios";
import { GIANT_SALARY_API_URL } from "../../../constants";


const GiantSalary = (props) => {
    const token = localStorage.getItem('accessToken');
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    let [salary, setSalary] = useState();
    let [salaryLabels, setSalaryLabels] = useState();
    let [salaryValues, setSalaryValues] = useState();

    const getSalary = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
        axios.get(GIANT_SALARY_API_URL + group_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setSalary(response.data);
            setSalaryLabels(response.data.map(obj => obj.experience_level));
            setSalaryValues(response.data.map(obj => obj.value));
        });
    };

    useEffect(() => {
        if (salaryLabels && salaryValues) {
            const documentStyle = getComputedStyle(document.documentElement);
            const data = {
                labels: salaryLabels,
                datasets: [
                    {
                        data: salaryValues,
                        label: 'Average Salary by experience',
                        backgroundColor: ['#9a77ca', '#7854ac'],
                        hoverBackgroundColor: ['#7854ac', '#6330ae']
                        // backgroundColor: [
                        //     documentStyle.getPropertyValue('--blue-500'), 
                        //     documentStyle.getPropertyValue('--yellow-500'), 
                        //     documentStyle.getPropertyValue('--green-500'), 
                        // ],
                        // hoverBackgroundColor: [
                        //     documentStyle.getPropertyValue('--blue-400'), 
                        //     documentStyle.getPropertyValue('--yellow-400'), 
                        //     documentStyle.getPropertyValue('--green-400'), 
                        // ]
                    }
                ]
            };
            const options = {
                indexAxis: 'y',
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [salaryLabels, salaryValues]);

    useEffect(() => getSalary(), []);

    return (
    <>
    {salaryLabels?.length > 0 ? (
        <div className="TalentInsightsContentBox">
            <h4>Salary</h4>
            <Chart type="bar" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
            <span className="TalentInsightsBoxDescription">
                The salary information refers to the salaries offered in vacancies for the {props.group?.name}. 
                The average monthly salary is based on the gross amount for a full-time job, excluding holiday pay, bonuses, profit sharing, etc.
                Data source Intelligence Group.
            </span>
        </div>
    ): (
        <>
        </>
    )}
    </>
);
}

export default GiantSalary;
