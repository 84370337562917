import { faDashboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { NumericFormat } from 'react-number-format';
import DashboardBox from "../../Global/Box";

const CampaignPostHog = (props) => {

    let posthogFunnelData = props.posthogFunnelData;

    return (
        <>
        {!posthogFunnelData ? (
            <>
                Loading
            </>
        ) : (
            <>
            <Row className="Content">
                <Col sm="12">
                    <h2>Meta</h2>
                </Col>
                <Col md="2" sm="12">
                    <DashboardBox
                        highlight={false}
                        title={'Ad Views'}
                        value={'-'}
                        step_conversion={posthogFunnelData?.welcome?.step_conversion}
                        description={'Ad Views'}
                    />
                </Col>
                <Col md="2" sm="12">
                    <DashboardBox
                        highlight={false}
                        title={'Ad Clicks'}
                        value={'-'}
                        step_conversion={posthogFunnelData?.welcome?.step_conversion}
                        description={'Ad Clicks'}
                    />
                </Col>
                <Col md="2" sm="12">
                    <DashboardBox
                        highlight={false}
                        title={'Ad Link Clicks'}
                        value={'-'}
                        step_conversion={posthogFunnelData?.welcome?.step_conversion}
                        description={'Ad Link Clicks'}
                    />
                </Col>
                <Col md="2" sm="12">
                    <DashboardBox
                        highlight={false}
                        title={'Cost per Link Click'}
                        value={'-'}
                        description={'Description'}
                    />
                </Col>
                <Col md="2" sm="12">
                    <DashboardBox
                        highlight={false}
                        title={'Ad Spending'}
                        value={'-'}
                        description={'Description'}
                    />
                </Col>
                <Col md="2" sm="12">
                    <DashboardBox
                        highlight={false}
                        title={'Ad Budget'}
                        value={'-'}
                        description={'Description'}
                    />
                </Col>
            </Row>
            <Row className="Content">
                <Col sm="12">
                    <h2>Candidate Funnel</h2>
                </Col>
                <Col md="2" sm="12">
                    <DashboardBox
                        highlight={false}
                        title={'Ad Link Clicks'}
                        value={'-'}
                        step_conversion={posthogFunnelData?.welcome?.step_conversion}
                        description={'Ad Link Clicks'}
                    />
                </Col>
                <Col md="2" sm="12">
                    <DashboardBox
                        highlight={false}
                        title={'Welcome'}
                        value={posthogFunnelData?.welcome?.unique_visitors}
                        step_conversion={posthogFunnelData?.welcome?.step_conversion}
                        description={'Unique visitors'}
                        comparison={'Compared to average'}
                    />
                </Col>
                <Col md="2" sm="12">
                    <DashboardBox
                        highlight={false}
                        title={'Respond'}
                        value={posthogFunnelData?.respond?.unique_visitors}
                        step_conversion={posthogFunnelData?.respond?.step_conversion}
                        description={'Unique visitors'}
                        comparison={'Compared to average'}
                    />
                </Col>
                <Col md="2" sm="12">
                    <DashboardBox
                        highlight={false}
                        title={'Apply'}
                        value={posthogFunnelData?.apply?.unique_visitors}
                        step_conversion={posthogFunnelData?.apply?.step_conversion}
                        description={'Candidates applied'}
                        comparison={'Compared to average'}
                    />
                </Col>

            </Row>
            </>
        )}
        </>
);
}

export default CampaignPostHog;
