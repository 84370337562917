import React, { Component, useState, useEffect } from "react";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link } from 'react-router-dom';

import axios from "axios";
import { ORDER_ITEMS_CONSUMED_VALUE_MONTH_API_URL } from "../../../constants";

import DashboardBox from "../../Global/Box";

const BoxConsumption = (props) => {
    const token = localStorage.getItem('accessToken');
    let [consumptionValueThisMonth, setConsumptionValueThisMonth] = useState();

    const getConsumptionValueThisMonth = () => {
        axios.get(ORDER_ITEMS_CONSUMED_VALUE_MONTH_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setConsumptionValueThisMonth(res.data));
    };

    useEffect(() => getConsumptionValueThisMonth(), []);

    return(
        <DashboardBox
            highlight={props.highlight}
            title={'Consumption'}
            value={consumptionValueThisMonth}
            currency={true}
            description={'Value of requested order items this month'}
            link={props.link}
            link_url={'/growth/consumption'}
            link_text={'Continue'}
        />
    );
}

export default BoxConsumption;
