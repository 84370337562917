import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Chart } from 'primereact/chart';

import axios from "axios";
import { GIANT_EDUCATION_LEVEL_API_URL } from "../../../constants";


const GiantEducationLevel = (props) => {
    const token = localStorage.getItem('accessToken');
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    let [data, setData] = useState();
    let [dataLabels, setDataLabels] = useState();
    let [dataValues, setDataValues] = useState();

    const getData = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
        axios.get(GIANT_EDUCATION_LEVEL_API_URL + group_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setData(response.data);
            setDataLabels(response.data.map(obj => obj.label));
            setDataValues(response.data.map(obj => obj.value * 100));
        });
    };

    useEffect(() => {
        if (dataLabels && dataValues) {
            const documentStyle = getComputedStyle(document.documentElement);
            const data = {
                labels: dataLabels,
                datasets: [
                    {
                        data: dataValues,
                        backgroundColor: ['#d401ab', '#03c7ff', '#006aff', '#710ce2'],
                        hoverBackgroundColor: ['#70005a', '#077b9b', '#02367f', '#4e089b']
                        // backgroundColor: ['#9a77ca', '#7854ac'],
                        // hoverBackgroundColor: ['#7854ac', '#6330ae']
                    }
                ]
            };
            const options = {
                plugins: {
                    legend: {
                        display: false // This will hide the label (legend)
                    }
                }
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [dataLabels, dataValues]);

    useEffect(() => getData(), []);

    return (
        <div className="TalentInsightsContentBox">
            <h4>Education Levels</h4>
            {dataValues && dataLabels ? (
                <Chart type="bar" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
            ): (
                <></>
            )}
            <span className="TalentInsightsBoxDescription">
                The distribution of the {props.group?.name}
                by level of education. Three categories are distinguished, namely: low (ISCED levels 0-2), medium (ISCED levels 3-4) and high (ISCED levels 5-8).
                Data source Intelligence Group.
            </span>
        </div>
);
}

export default GiantEducationLevel;
