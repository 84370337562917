import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { Skeleton } from 'primereact/skeleton';
import Moment from 'react-moment';

const AdminGroupList = (props) => {
    let groups = props.groups;
    return (
    <>
      <div className="TableContainer">
        <Table className="WhiteTable TalentInsightsTable">
            <thead>
            <tr>
                <th width="5%">Code</th>
                <th width="85%">Name</th>
                <th width="10%" className="text-right">Past Campaigns</th>
            </tr>
            </thead>
            <tbody>
            {!groups || groups?.length <= 0 ? (
                <tr>
                    <td colSpan="3">
                        {!groups <= 0 ? (
                            <Skeleton className="mb-2"></Skeleton>                    
                        ) : (
                            <b>Ops, no groups here yet</b>
                        )}                        
                    </td>
                </tr>
            ) : (
                groups?.map(group => (
                <tr key={group.id}>
                    <td width="5%">
                        {group.code}
                    </td>
                    <td width="85%">
                        <Link to={`/talent-insights/groups/${group.code}`}>
                            {props.current_user?.current_language_code == 'en' ? `${group.name}` : ''}
                            {props.current_user?.current_language_code == 'fi' ? `${group?.name_fi}` : ''}
                            {props.current_user?.current_language_code == 'de' ? `${group?.name_de}` : ''}
                        </Link>
                    </td>
                    <td width="10%" align="right">
                        {group.sample_job_count}
                    </td>
                </tr>
                ))
            )}
            </tbody>
        </Table>
      </div>
    </>
    );
}

export default AdminGroupList;
