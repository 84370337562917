import React, { Component, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { AI_WELCOME_CONTENT_API_URL } from "../../constants";

import { ProgressSpinner } from "primereact/progressspinner";
import { ProgressBar } from 'primereact/progressbar';

import AISuiteHeader from "./Header";
import AIIncubatorJobDetails from "./JobDetails";

const WelcomeAI = forwardRef((props, ref) => {
    const token = localStorage.getItem('accessToken');
    const [welcome, setWelcome] = useState();
    const [progress, setProgress] = useState(0);

    const runWelcomeAI = (job_id) => {
        console.log('Welcome AI running', job_id)
        getWelcome(job_id);
        const interval = setInterval(() => {
            setProgress(prev => {
                if (prev < 100) {
                    return prev + 1; // Increase progress by 1%
                } else {
                    clearInterval(interval); // Stop when it reaches 100%
                    return prev; // Return 100% on completion
                }
            });
        }, 150); // Update every 100ms to complete in 15 seconds (1500ms / 100 = 10ms)

        return () => clearInterval(interval); // Cleanup on unmount
    };
    
    useImperativeHandle(ref, () => ({
        runWelcomeAI: runWelcomeAI, // Expose the function
    }));

    const getWelcome = (job_id) => {
        const payload = {
            job_id: job_id, 
        };
        // axios.get(AI_WELCOME_CONTENT_API_URL + '?job_title=' + localStorage.getItem('job_title'))
        axios.post(AI_WELCOME_CONTENT_API_URL, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            console.log('set')
            setWelcome(response.data);
        })        
        .catch((error) => {
            console.error('Error fetching welcome content:', error);
        });
    };

    console.log(welcome)

    return (
        <div className="Content">
            <Row>
                <Col>
                    {!welcome ? (
                        <ProgressBar value={progress} />
                    ) : (
                        <div className="WelcomeContent">
                            <h2>{welcome?.title}</h2>
                            <span className="Lead">
                                {welcome?.subtitle}
                            </span>
                            <p>{welcome?.content?.introduction}</p>
                            <ul>
                                {welcome?.content?.benefits?.map((benefit, index) => (
                                <li key={index}>{benefit}</li>
                                ))}
                            </ul>
                            <p>{welcome?.content?.positionDetails}</p>
                            <p>{welcome?.content?.companyInformation}</p>
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
})

export default WelcomeAI;
