import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const API_URL = process.env.REACT_APP_LOCAL === 'true' ? 'http://localhost:5555/api' : 'https://jobilla-brains.rede.fi/api';

// Regular registration
const register = (username, email, password) => {
    return axios.post(API_URL + '/register/', {
        username,
        email,
        password,
    });
};

// Regular login with username and password
const login = async (username, password) => {
    const response = await axios.post(API_URL + '/token/', {
        username,
        password,
    });
    if (response.data.access) {
        const { access, refresh } = response.data;
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);
        console.log(access);
    }
    console.log('LOGGED IN');
    return response.data;
};

// Google login
const googleLogin = async (token) => {
    try {
        const response = await axios.post(API_URL + '/auth/google-login/', {
            token: token,
        });
        if (response.data.access) {
            const { access, refresh } = response.data;
            localStorage.setItem('user', JSON.stringify(response.data)); // Store user details
            localStorage.setItem('accessToken', access);
            localStorage.setItem('refreshToken', refresh);
            console.log('Google Access Token:', access);
        }
        console.log('GOOGLE LOGGED IN');
        window.location.href = '/dashboard';

        return response.data;
    } catch (error) {
        console.error('Google login failed:', error);
        // window.location.href = '/no-permissions';
    }
};

// Logout functionality
const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
};

// Refresh token function
const refreshToken = async () => {
    try {
        const storedRefreshToken = localStorage.getItem('refreshToken');
        if (storedRefreshToken) {
            const response = await axios.post(API_URL + '/token/refresh/', {
                refresh: storedRefreshToken,
            });
            if (response.data.access) {
                const { access } = response.data;
                localStorage.setItem('accessToken', access);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + access; // Update Axios with the new access token
                console.log('Token refreshed');
                return access;  // Return the new access token
            }
        } else {
            throw new Error('No refresh token available');
        }
    } catch (error) {
        console.error('Token refresh failed:', error);
        logout();  // Log the user out if refresh fails
    }
    return null;
};

// Axios Interceptor for handling 401 errors and refreshing the token
axios.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;

        // Check if the error status is 401, token is expired, and we haven't retried yet
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            window.location.href = '/logout';  

            // Try to refresh the token
            // const newAccessToken = await refreshToken();
            // if (newAccessToken) {
            //     // Retry the original request with the new token
            //     originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
            //     return axios(originalRequest);
            // }
        }

        // If the token refresh failed, logout the user and do not retry the request
        return Promise.reject(error);
    }
);


export default {
    register,
    login,
    googleLogin,
    logout,
    // refreshToken,
};
