import React, { Component, useState, useEffect } from "react";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link } from 'react-router-dom';

import axios from "axios";
import { ORDER_VALUE_MONTH_API_URL } from "../../../constants";

import DashboardBox from "../../Global/Box";

const BoxSales = (props) => {
    const token = localStorage.getItem('accessToken');
    let [orderValueThisMonth, setOrderValueThisMonth] = useState();

    const getOrderValueThisMonth = () => {
        axios.get(ORDER_VALUE_MONTH_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setOrderValueThisMonth(res.data));
    };

    useEffect(() => getOrderValueThisMonth(), []);

    return(
        <DashboardBox
            highlight={props.highlight}
            title={'Sales'}
            value={orderValueThisMonth}
            currency={true}
            description={'Total closed deal value in this month'}
            link={props.link}
            link_url={'/growth/sales'}
            link_text={'Continue'}
        />
    );
}

export default BoxSales;
