import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Chart } from 'primereact/chart';

import axios from "axios";
import { GIANT_GENDER_API_URL } from "../../../constants";


const GiantGender = (props) => {
    const token = localStorage.getItem('accessToken');
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    let [genders, setGender] = useState();
    let [genderLabels, setGenderLabels] = useState();
    let [genderValues, setGenderValues] = useState();
    const [error, setError] = useState(null);

    const getGender = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
        axios.get(GIANT_GENDER_API_URL + group_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            setGender(response.data);
            setGenderLabels(response.data.map(obj => obj.label));
            setGenderValues(response.data.map(obj => obj.value * 100));
            setError(null); // Clear any previous errors
        })
        .catch(error => {
            if (error.response) {
              if (error.response.status === 404) {
                setError('Resource not found (404)');
              } else {
                setError(`An error occurred: ${error.response.status}`);
              }
            } else if (error.request) {
              setError('No response received from server');
            } else {
              setError('Error in setting up the request');
            }
            setGender(null); // Clear any previous data
          });
    };

    useEffect(() => {
        if (genderLabels && genderValues) {
            const documentStyle = getComputedStyle(document.documentElement);
            const data = {
                labels: genderLabels,
                datasets: [
                    {
                        data: genderValues,
                        backgroundColor: ['#9a77ca', '#7854ac'],
                        hoverBackgroundColor: ['#7854ac', '#6330ae']
                    }
                ]
            };
            const options = {
                plugins: {
                    legend: {
                        display: false // This will hide the label (legend)
                    }
                }
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [genderLabels, genderValues]);

    useEffect(() => getGender(), []);

    return (
        <div className="TalentInsightsContentBox">
            <h4>Gender</h4>
            {genderValues && genderLabels ? (
                <Chart type="bar" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
            ): (
                <></>
            )}
            <span className="TalentInsightsBoxDescription">
                The distribution of the {props.group?.name} by gender. 
                Data source Intelligence Group.
            </span>
        </div>
);
}

export default GiantGender;
