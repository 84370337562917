import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row, Table, Card } from "reactstrap";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { Skeleton } from 'primereact/skeleton';
import { Link } from "react-router-dom";
import Moment from 'react-moment';

import { Knob } from 'primereact/knob';
import { ProgressBar } from 'primereact/progressbar';
import { TabView, TabPanel } from 'primereact/tabview';

import axios from "axios";
import { GIANT_API_CALL_URL, GROUPS_API_URL, OCCUPATIONS_API_URL, GIANT_FEASIBILITIES_API_URL, JOB_SAMPLES_API_URL } from "../../constants";
import AdminOccupationList from "./OccupationList";
import TalentInsightsHeader from "./Header";
import GiantGender from "./Giant/Gender";
import GiantJobMarketActivity from "./Giant/JobMarketActivity";
import GiantWorkExperience from "./Giant/WorkExperience";
import GiantSalary from "./Giant/Salary";
import GiantEducationLevel from "./Giant/EducationLevel";
import GiantTargetGroupSize from "./Giant/TargetGroupSize";
import AdminJobSampleList from "./JobSamples";
import JobSampleDistribution from "./JobSampleDistribution";
import GiantJobBenefits from "./Giant/JobBenefits";
import GiantPullFactors from "./Giant/PullFactors";
import GiantAgeDistribution from "./Giant/AgeDistribution";
import GiantSourcingPressure from "./Giant/SourcingPressure";
import Guarantee from "./Guarantee";
import GiantDemographics from "./Giant/Demographics";
import GiantAppreciation from "./Giant/Appreciation";
import GiantJobChanges from "./Giant/JobChanges";
import GiantSocialMediaApps from "./Giant/SocialMediaApps";
import GiantwillingnessTravel from "./Giant/WillingnessTravel";

const OccupationGroup = (props) => {
    const token = localStorage.getItem('accessToken');
    let [group, setGroup] = useState();
    let [giant, setGiant] = useState();
    let [occupation, setOccupation] = useState();
    let [feasibility, setFeasibility] = useState();
    let [occupations, setOccupations] = useState([]);
    let [job_samples, setJobSamples] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [error, setError] = useState(null);
    let [shortDescription, setShortDescription] = useState('');

    let [tab1, setTab1] = useState('Target Group EN');


    const handleLanguage = (value) => {
        // setSelectedLanguage(value);
    };


    const getGroup = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
            axios.get(GROUPS_API_URL + group_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
            setGroup(response.data);
            setShortDescription(response.data?.description.length > 1000 ? response.data?.description.slice(0, 1000) + '...' : response.data?.description)
            // if (props.current_user?.current_country_code == 'FI') {
            //     setTab1('Heloss')
            // }
        });
    };

    const runGroupGiantCall = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
            let complete_url = GROUPS_API_URL + group_id + '/giant';
        // console.log(complete_url)
        axios.get(complete_url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            // setGiant(response.data);
            // console.log(giant)
        });
    };

    // console.log(giant)

    const getFeasibility = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
        axios.get(GIANT_FEASIBILITIES_API_URL + group_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            setFeasibility(response.data)
        })
        .catch(error => {
            if (error.response) {
              if (error.response.status === 404) {
                setError('Resource not found (404)');
              } else {
                setError(`An error occurred: ${error.response.status}`);
              }
            } else if (error.request) {
              setError('No response received from server');
            } else {
              setError('Error in setting up the request');
            }
            setFeasibility(null); // Clear any previous data
          });
    };


    const getFilteredOccupations = () => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
            axios.get(OCCUPATIONS_API_URL + '?group=' + group_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(res => setOccupations(res.data));
    };

    const getJobSamples = () => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
            axios.get(JOB_SAMPLES_API_URL + '?isco=' + group_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(res => setJobSamples(res.data));
    };


    const getOccupation = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let occupation_id = url_components[4]
        if (occupation_id) {
            axios.get(OCCUPATIONS_API_URL + occupation_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setOccupation(response.data)
            });    
        }
    };



    useEffect(() => getGroup(), []);
    useEffect(() => runGroupGiantCall(), []);
    useEffect(() => getOccupation(), []);
    useEffect(() => getFeasibility(), []);
    useEffect(() => getFilteredOccupations(), []);
    useEffect(() => getJobSamples(), []);
    

    const valueTemplate = (value) => {
        return (
            <React.Fragment>
                <b>{feasibility?.label}: {feasibility?.value}/10</b>
            </React.Fragment>
        );
    };

    return (
        <Container className="Main MainAdminFullWidth MainWithHero">
        <div className="Centralizer White SingleJobGroup">
        <TalentInsightsHeader 
                current_user={props.current_user}
            />
            <Row>
                <Col>
                    <div className="Box DarkPurple TalentInsightsBox">
                        {/* <h4>Job Group: {group?.code}</h4> */}
                        <h2>
                            {props.current_user?.current_language_code == 'en' ? (
                                <>
                                {group?.name} {occupation ? '/' :''} {occupation?.name}
                                </>
                            ): ''}
                            {props.current_user?.current_language_code == 'fi' ? (
                                <>
                                {group?.name_fi} {occupation ? '/' :''} {occupation?.name_fi}
                                </>
                            ): ''}
                            {props.current_user?.current_language_code == 'de' ? (
                                <>
                                {group?.name_de} {occupation ? '/' :''} {occupation?.name_de}
                                </>
                            ): ''}
                        </h2>
                        {/* <p>
                        {props.current_user?.current_language_code == 'en' && occupation?.alt_labels ? `${occupation?.alt_labels}` : ''}
                        {props.current_user?.current_language_code == 'fi' && occupation?.alt_labels_fi ? `${occupation?.alt_labels_fi}` : ''}
                        {props.current_user?.current_language_code == 'de' && occupation?.alt_labels_de ? `${occupation?.alt_labels_de}` : ''}
                        </p> */}
                        {group?.category ? (
                        <>
                        <Link to={`/talent-insights/clusters/${group.category}`} className="TalentInsightsLightLink">
                            {props.current_user?.current_language_code == 'en' ? `${group.category_name?.name}` : ''}
                            {props.current_user?.current_language_code == 'fi' ? `${group.category_name?.name_fi}` : ''}
                            {props.current_user?.current_language_code == 'de' ? `${group.category_name?.name_de}` : ''}
                        </Link>
                        </>
                        ) : ('')}
                        <span className="TalentInsightsLightLink">  
                            &nbsp;
                            &raquo;
                            &nbsp;
                        </span>
                        {group?.pathway ? (
                        <>
                        <Link to={`/talent-insights/pathways/${group.pathway}`} className="TalentInsightsLightLink">
                            {props.current_user?.current_language_code == 'en' ? `${group.pathway_name?.name}` : ''}
                            {props.current_user?.current_language_code == 'fi' ? `${group.pathway_name?.name_fi}` : ''}
                            {props.current_user?.current_language_code == 'de' ? `${group.pathway_name?.name_de}` : ''}
                        </Link>
                        &nbsp;
                        &nbsp;
                        </>
                        ) : ('')}

                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header={props.current_user?.current_language_code == 'fi' ? ('Kohderyhmä') : ('Target Group')}>
                            <Row>
                                <Col sm="12">
                                    <GiantJobMarketActivity 
                                        group={group}
                                        current_user={props.current_user}
                                        // giant={giant}
                                    />
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel header={props.current_user?.current_language_code == 'fi' ? ('Kohdeyleisö') : ('Demographics')}>
                            <Row>
                                <Col sm="12">
                                    <h2 className="TalentInsightsCenteredTitle">
                                        {props.current_user?.current_language_code == 'en' ? 'What kind of people ' + `${group?.name}` + ' are?' : ''}
                                        {props.current_user?.current_language_code == 'fi' ? `${group?.name_fi}` + ' kohderyhmänä' : ''}
                                        {props.current_user?.current_language_code == 'de' ? 'What kind of people ' + `${group?.name_de}` + ' are?' : ''}
                                        
                                    </h2>
                                </Col>
                            </Row>
                            <GiantDemographics 
                                group={group}
                            />
                        </TabPanel>
                        <TabPanel header={props.current_user?.current_language_code == 'fi' ? ('Arvostus') : ('Appreciation')}>
                            <Row>
                                <Col sm="12">
                                    <h2 className="TalentInsightsCenteredTitle">
                                        {props.current_user?.current_language_code == 'fi' ? 'Mitä ' + `${group?.name_fi}` + '  arvostavat?' : 'What do ' + `${group?.name}` + '  appreciate?'}
                                    </h2>
                                </Col>
                            </Row>
                            <GiantAppreciation 
                                group={group}
                            />
                        </TabPanel>
                        <TabPanel header={props.current_user?.current_language_code == 'fi' ? ('Käyttäytyminen') : ('Behaviour')}>
                            <Row>
                                <Col sm="12">
                                    <h2 className="TalentInsightsCenteredTitle">
                                        {props.current_user?.current_language_code == 'fi' ? 'Miten ' + `${group?.name_fi}` + '  käyttäytyvät?' : 'How do ' + `${group?.name}` + '  behave?'}
                                    </h2>
                                </Col>
                                <Col sm="6">
                                    <GiantJobChanges 
                                        group={group}
                                    />
                                </Col>
                                <Col sm="6">
                                    <GiantwillingnessTravel
                                        group={group}
                                    />
                                </Col>
                                <Col sm="6">
                                    <GiantSocialMediaApps 
                                        group={group}
                                    />
                                </Col>
                            </Row>
                        </TabPanel>
                        {props.current_user?.current_country_code == 'DE' || props.current_user?.current_country_code == 'NL' ? (
                        <TabPanel header={props.current_user?.current_language_code == 'fi' ? ('Palkka') : ('Salary')}>
                            <Row>
                                <Col sm="12">
                                    <h2 className="TalentInsightsCenteredTitle">
                                        What do {group?.name} earn?
                                    </h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <GiantSalary 
                                        group={group}
                                    />
                                </Col>
                            </Row>
                        </TabPanel>
                        ) : ('')}
                        <TabPanel header={props.current_user?.current_language_code == 'fi' ? ('Kuvaus') : ('Description')}>
                            <Row>
                                <Col sm="12">
                                    <div className="TalentInsightsContentBox">
                                        <span className="WebsiteServiceTitle">Who are they?</span>
                                        <h4>{group?.name}</h4>
                                        <p>{shortDescription}</p>
                                    </div>
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel header={props.current_user?.current_language_code == 'fi' ? ('Ammattinimikkeet') : ('Related Job Titles')}>
                            <h3>Related Job Titles</h3>
                            <AdminOccupationList
                                occupations={occupations}
                                current_user={props.current_user}
                                forceLink={true}
                            />
                        </TabPanel>
                        {/* <TabPanel header={props.current_user?.current_language_code == 'fi' ? ('Toteutuneet kampanjat') : ('Past Campaigns')}>
                        </TabPanel> */}
                        <TabPanel header="Jobilla Predict &trade;">
                            <Row>
                                <Col sm="12">
                                    {feasibility ? (
                                        <h2 className="TalentInsightsCenteredTitle">
                                            {feasibility.label}: {feasibility?.value}/10
                                        </h2>
                                        // <div className={props.feasibility.value > 6 ? "Box FeasibilityBox FeasibilityDifficult" : "Box FeasibilityBox"}>
                                        //     <h2>{props.feasibility.label}</h2>
                                        //     <span className="FeasibilityNumber">{props.feasibility?.value}/10</span>
                                        // </div>
                                    ) : ''}
                                </Col>
                            </Row>
                            <Guarantee feasibility={feasibility}/>
                            <Row>
                                <Col>
                                    {job_samples && job_samples?.length > 0 ? (
                                    <AdminJobSampleList
                                        job_samples={job_samples}
                                    />
                                    ) : ('')}
                                </Col>
                                <Col>
                                    {job_samples && job_samples?.length > 0 ? (
                                    <JobSampleDistribution />
                                    ) : ('')}
                                </Col>
                            </Row>
                        </TabPanel>
                    </TabView>
                </Col>
            </Row>
            </div>
        </Container>
    );
};
export default OccupationGroup;