import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import Moment from 'react-moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

const AdminClusterCards = (props) => {
    let clusters = props.clusters;
    return (
    <>
      <Row>
            {!clusters || clusters?.length <= 0 ? (
                <Col>
                    <b>Ops, no clusters here yet</b>
                </Col>
            ) : (
                clusters?.map(cluster => (
                <Col sm="3" key={cluster.pk}>
                    <Link to={`/talent-insights/clusters/${cluster.pk}`} className="ClusterCardLink">
                    <Card className="ClusterCard">
                        <h4>
                            {props.current_user?.current_language_code == 'en' ? `${cluster?.name}` : ''}
                            {props.current_user?.current_language_code == 'fi' ? `${cluster?.name_fi}` : ''}
                            {props.current_user?.current_language_code == 'de' ? `${cluster?.name_de}` : ''}
                        </h4>
                        <span><FontAwesomeIcon icon={faBriefcase} /> {cluster?.sample_job_count}</span>
                    </Card>
                    </Link>
                </Col>
                ))
            )}
      </Row>
    </>
    );
}

export default AdminClusterCards;
