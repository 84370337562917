import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NumericFormat } from 'react-number-format';
import { Col, Container, Row, Table, Card } from "reactstrap";

import { Chart } from 'primereact/chart';

import axios from "axios";
import { GIANT_JOB_MARKET_ACTIVITY_API_URL } from "../../../constants";
import GiantTargetGroupSize from "./TargetGroupSize";
import GiantSourcingPressure from "./SourcingPressure";
import GiantAspectsApplicationProcess from "./AspectsApplicationProcess";


const GiantJobMarketActivity = (props) => {
    const token = localStorage.getItem('accessToken');
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    let [data, setData] = useState();
    let [dataLabels, setDataLabels] = useState();
    let [dataValues, setDataValues] = useState();

    const getData = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
        axios.get(GIANT_JOB_MARKET_ACTIVITY_API_URL + group_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setData(response.data);
            setDataLabels(response.data.map(obj => obj.label));
            setDataValues(response.data.map(obj => obj.value * 100));
        });
    };

    useEffect(() => {
        if (dataLabels && dataValues) {
            const documentStyle = getComputedStyle(document.documentElement);
            const data = {
                labels: dataLabels,
                datasets: [
                    {
                        data: dataValues,
                        backgroundColor: ['#7213e3', '#52d4f7', '#dfe2e5'], 
                        hoverBackgroundColor: ['#5905bd', '#24aed3', '#b5b5b5']
                    }
                ]
            };
            const options = {
                cutout: '50%',
                plugins: {
                    legend: {
                        position: 'bottom', // Positions the legend below the pie chart
                    }
                },
                layout: {
                    padding: {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }
                },
                elements: {
                    arc: {
                        backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background for pie sections
                    },
                },
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [dataLabels, dataValues]);

    useEffect(() => getData(), []);

    let group = props.group;

    return (
        <Row>
            <Col sm="12">
                <h2 className="TalentInsightsCenteredTitle">
                    {props.current_user?.current_language_code == 'en' ? (
                    <>
                        <NumericFormat
                            value={dataValues?.[1]}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={0}
                        />
                        % of {group?.name.toLowerCase()} are passively looking for a job
                    </>
                    ) : ''}
                    {props.current_user?.current_language_code == 'fi' ? (
                    <>
                        <NumericFormat
                            value={dataValues?.[1]}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={0}
                        />
                        % kohderyhmästä ovat passiivisia kandidaatteja
                    </>
                    ) : ''}
                    {props.current_user?.current_language_code == 'de' ? (
                    <>
                        <NumericFormat
                            value={dataValues?.[1]}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={0}
                        />
                        % of the {group?.name_de} are passive
                    </>
                    ) : ''}
                </h2>
            </Col>
            <Col sm="4">
                <div className="TalentInsightsContentBox White Relative">
                    {dataLabels && dataValues ? (
                        <Chart type="pie" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
                    ): (
                        <></>
                    )}
                </div>
            </Col>
            <Col sm="4">
                <GiantTargetGroupSize 
                    current_user={props.current_user}
                    group={props.group}
                />
            </Col>
            <Col sm="4">
                <GiantAspectsApplicationProcess 
                    group={props.group}
                />
            </Col>
            {/* <Col sm="12">
                <h4>
                    Active Candidates
                    <NumericFormat
                        value={dataValues?.[0]}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={0}
                    />
                    %
                </h4>
                <p>
                    Majority of the active job seekers are <strong>either unemployed or frustrated</strong> at their current job.
                </p>
                <h4>
                    Passive Candidates:&nbsp; 
                    <NumericFormat
                        value={dataValues?.[1]}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={0}
                    />
                    %
                </h4>
                <p>
                    Majority are already employed but <strong>open for new opportunities</strong>.
                </p>
            </Col> */}
        </Row>
);
}

export default GiantJobMarketActivity;
