import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table } from "reactstrap";
import { NumericFormat } from 'react-number-format';

import { Chart } from 'primereact/chart';

import axios from "axios";
import { GIANT_JOB_CHANGES_API_URL } from "../../../constants";


const GiantJobChanges = (props) => {
    const token = localStorage.getItem('accessToken');
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    let [data, setData] = useState();
    let [dataLabels, setDataLabels] = useState();
    let [dataValues, setDataValues] = useState();

    const getData = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
        axios.get(GIANT_JOB_CHANGES_API_URL + group_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setData(response.data);
            setDataLabels(response.data.map(obj => obj.label));
            setDataValues(response.data.map(obj => obj.value * 100));
        });
    };

    useEffect(() => {
        if (dataLabels && dataValues) {
            const documentStyle = getComputedStyle(document.documentElement);
            const data = {
                labels: dataLabels,
                datasets: [
                    {
                        data: dataValues,
                        // backgroundColor: ['#9a77ca', '#7854ac'],
                        // hoverBackgroundColor: ['#7854ac', '#6330ae']
                        backgroundColor: ['#03c7ff', '#006aff', '#d401ab', '#710ce2'],
                        hoverBackgroundColor: ['#077b9b', '#02367f', '#70005a', '#4e089b']
                    }
                ]
            };
            const options = {
                plugins: {
                    legend: {
                        display: false // This will hide the label (legend)
                    }
                }
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [dataLabels, dataValues]);

    useEffect(() => getData(), []);

    return (
        <div className="TalentInsightsContentBox">
            <h4>Job Changes</h4>
            {dataValues && dataLabels ? (
                <Chart type="bar" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
            ): (
                <></>
            )}
            <span className="TalentInsightsBoxDescription">
                Indicates which part of {props.group?.name} found another job in the past year. 
                A distinction is made between external job changes (with a new employer) and internal job changes (another job with the same employer).
                Data source Intelligence Group.
            </span>
        </div>
);
}

export default GiantJobChanges;
