import React, { Component, Fragment, useState, useEffect, useHistory } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs, faMoon, faSignOut } from '@fortawesome/free-solid-svg-icons'
import background from "../../img/home_background.png";
import background2 from "../../img/home_bg_1.avif";


import { Dropdown } from 'primereact/dropdown';
import DropdownCompanies from "./Dropdown";

const TopBar = (props) => {
    const [dropdownVisible, setDropdown] = useState(false);
    const [darkMode, setDarkMode] = useState(false);

    const toggleDropdown = () => {
        setDropdown(prevState => ({ dropdownVisible: !prevState.dropdownVisible }));
    };

    const toggleDarkMode = () => {
      props.toggleDarkMode();
    };

    const changeCurrentCompany = (new_current_company_id) => {
        props.changeCurrentCompany(new_current_company_id);
    };
  
    const changeCurrentLanguage = (language_shortcode) => {
        props.changeCurrentLanguage(language_shortcode);
    };

    const changeCurrentCountry = (country_shortcode) => {
      props.changeCurrentCountry(country_shortcode);
  };



    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    useEffect(() => props.checkAdmin(window.location.pathname), []);

    return (
      <Fragment>
        <div className={pathname == '/' || pathname == '/explorer' || pathname.includes('admin') ? "TopBar White" : "TopBar"}>
          <div className="Centralizer">
            <ul className="HeaderNav">
                {props.user ? (
                <>
                <li className="HeaderNavItem">
                  <Link to="/logout" className="HeaderIconLink HeaderLogout">
                    <FontAwesomeIcon icon={faSignOut} />
                  </Link>
                </li>
                {/* <div className="HeaderDivider"></div>
                <li className="HeaderNavItem">
                  <Link to="/admin/users" className="HeaderIconLink">
                    <FontAwesomeIcon icon={faCogs} />
                  </Link>
                </li> */}
                <div className="HeaderDivider"></div>
                <li className="HeaderNavItem">
                  <span className="HeaderIconLink">
                    <FontAwesomeIcon icon={faMoon} onClick={(e) => toggleDarkMode()} />
                  </span>
                </li>
                {splitLocation[1] === 'talent-insights' ? (
                <>
                <div className="HeaderDivider"></div>
                <li className="HeaderNavItem">
                      <Dropdown value={props.languages[props.current_user?.current_language_order]} onChange={(e) => changeCurrentLanguage(e.value)} options={props.languages} optionLabel="name" 
                      placeholder="Select Language" className="HeaderDropdown w-full md:w-14rem mt-3" />
                </li>
                <div className="HeaderDivider"></div>
                <li className="HeaderNavItem">
                      <Dropdown value={props.countries[props.current_user?.current_country_order]?.value} onChange={(e) => changeCurrentCountry(e.value)} options={props.countries} optionLabel="name" 
                      placeholder="Select Country" filter className="w-full md:w-14rem mt-3" />
                </li>
                </>
                ) : ('')}
                </>
                ) : ('')}
            </ul>

            {/* <Link to="/">
                <img src="/img/jobilla_square.png" className="HeaderLogo img-fluid"/>
            </Link> */}

          </div>
        </div>
      </Fragment>
    );
}

export default TopBar;
