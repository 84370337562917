import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStream, faUsers, faUserFriends, faShoppingCart, faChartPie, faTv, faCogs } from '@fortawesome/free-solid-svg-icons'

import axios from "axios";
import { USER_COMPANIES_API_URL } from "../../constants";

const DropdownCompanies = (props) => {
    let [user_companies, setUserCompanies] = useState([]);
    
    const getUserCompanies = () => {
        axios.get(USER_COMPANIES_API_URL).then(res => setUserCompanies(res.data));
    };

    useEffect(() => getUserCompanies(), []);

    if (props.dropdownVisible) {
        return (
            <div className="Dropdown">
                <div className="DropdownHeader">
                    <h4>My companies ({user_companies.length})</h4>
                </div>
                <ul className="DropdownList">
                    {!user_companies || user_companies.length <= 0 ? (
                    <li>
                        <span className="NavLink">
                            Ops, no companies
                        </span>
                    </li>
                    ) : (
                    user_companies.map(company => (
                        <li key={company.pk}>
                            <span className="NavLink" onClick={() => props.changeCurrentCompany(company.company)}>
                                {company.company_name}
                            </span>
                        </li>
                    ))
                    )}
                </ul>        
            </div>
        );
    } else {
        return null;
    }
}

export default DropdownCompanies;
