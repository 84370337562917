import { faDashboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const CampaignsHeader = (props) => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    return (
        <>
        <h1>Brains Admin</h1>
        <Row>
            <Col>
                <div className="AdminFilters">
                    <ul className="StatusPillFilters">
                        <li className="NavItem">
                            <Link to="/admin/users" className={splitLocation[2] == 'users' ? "NavLink Active" : "NavLink"}>
                                Users
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/admin/user-analytics" className={splitLocation[2] == 'user-analytics' ? "NavLink Active" : "NavLink"}>
                                User Analytics
                            </Link>
                        </li>
                    </ul>
                </div>
            </Col>
        </Row>
        </>
);
}

export default CampaignsHeader;
