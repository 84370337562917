import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Row, Table } from "reactstrap";
import { faStream, faBuilding, faUsers, faUserFriends, faShoppingCart, faChartPie, faBrush, faCogs, faScaleBalanced, faRightLeft, faBook, faExchange } from '@fortawesome/free-solid-svg-icons'

import { Dropdown } from 'primereact/dropdown';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronRightIcon } from 'primereact/icons/chevronright';

const TalentInsightsHeader = (props) => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    return (
        <>
        <h1 className="TalentInsightsMainTitle">Talent Insights AI &trade;</h1>
        <Row>
            <Col>
                <div className="AdminFilters">
                    <ul className="StatusPillFilters">
                        <li className="NavItem">
                            <Link to="/talent-insights" className={!splitLocation[2] ? "NavLink Active" : "NavLink"}>
                                {props.current_user?.current_language_code == 'en' ? 'Job Titles' : ''}
                                {props.current_user?.current_language_code == 'fi' ? 'Ammatit' : ''}
                                {props.current_user?.current_language_code == 'de' ? 'Job Titles' : ''}
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/talent-insights/groups" className={splitLocation[2] == 'groups' ? "NavLink Active" : "NavLink"}>
                                {props.current_user?.current_language_code == 'en' ? 'Job Groups' : ''}
                                {props.current_user?.current_language_code == 'fi' ? 'Ammattiryhmät' : ''}
                                {props.current_user?.current_language_code == 'de' ? 'Job Groups' : ''}
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/talent-insights/pathways" className={splitLocation[2] == 'pathways' ? "NavLink Active" : "NavLink"}>
                                {props.current_user?.current_language_code == 'en' ? 'Career Pathways' : ''}
                                {props.current_user?.current_language_code == 'fi' ? 'Urapolut' : ''}
                                {props.current_user?.current_language_code == 'de' ? 'Career Pathways' : ''}
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/talent-insights/clusters" className={splitLocation[2] == 'clusters' ? "NavLink Active" : "NavLink"}>
                                {props.current_user?.current_language_code == 'en' ? 'Career Clusters' : ''}
                                {props.current_user?.current_language_code == 'fi' ? 'Urakategoriat' : ''}
                                {props.current_user?.current_language_code == 'de' ? 'Career Clusters' : ''}
                            </Link>
                        </li>
                        {/* <li className="NavItem">
                            <Link to="/talent-insights/regions" className={splitLocation[2] == 'regions' ? "NavLink Active" : "NavLink"}>
                                Regions
                            </Link>
                        </li> */}
                        {/* <li className="NavItem">
                            <Link to="/talent-insights/skills" className={splitLocation[2] == 'skills' ? "NavLink Active" : "NavLink"}>
                                {props.current_user?.current_language_code == 'en' ? 'Skills' : ''}
                                {props.current_user?.current_language_code == 'fi' ? 'Taidot' : ''}
                                {props.current_user?.current_language_code == 'de' ? 'Skills' : ''}
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </Col>
        </Row>
        </>
);
}

export default TalentInsightsHeader;
