import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table } from "reactstrap";
import { NumericFormat } from 'react-number-format';

import { Chart } from 'primereact/chart';

import axios from "axios";
import { GIANT_TARGET_GROUP_SIZE_API_URL } from "../../../constants";


const GiantTargetGroupSize = (props) => {
    const token = localStorage.getItem('accessToken');
    let [targetGroupSize, setTargetGroupSize] = useState();

    const getTargetGroupSize = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
        axios.get(GIANT_TARGET_GROUP_SIZE_API_URL + group_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setTargetGroupSize(response.data);
        });
    };

    useEffect(() => getTargetGroupSize(), []);

    return (
        <div className="TalentInsightsContentBox">
            <h4 className="TalentInsightBoxCentered">Target Group Size</h4>
            <Table className="">
                <thead className="TalentInsightsTableHead">
                    <tr>
                        <th>Region</th>
                        <th className="text-right">Size</th>
                    </tr>
                </thead>
                <tbody>
                {!targetGroupSize || targetGroupSize?.length <= 0 ? (
                    <tr>
                        <td colSpan="2">
                            <b>Ops, no target group data here yet</b>
                        </td>
                    </tr>
                ) : (
                    targetGroupSize?.map(group => (
                    <tr key={group.id}>
                        <td width="70%">
                            {group.region_label}
                        </td>
                        <td width="30%" align="right">
                            <NumericFormat
                                value={group.value}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={0}
                            />
                        </td>
                    </tr>
                    ))
                )}
                </tbody>
            </Table>
            <span className="TalentInsightsBoxDescription">
                An estimate of the total number of people working as {props.group?.name}. Data source Eurostat through Intelligence Group.
            </span>
        </div>
);
}

export default GiantTargetGroupSize;
