import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { AI_LOGS_API_URL, AI_LOGS_COMPLETION_RATE_API_URL, AI_LOGS_COMPLETION_TIME_API_URL, AI_JOB_LOGS_API_URL } from "../../constants";

import AISuiteHeader from "./Header";
import AIIncubatorJobTitle from "./JobDetails";
import { ProgressSpinner } from "primereact/progressspinner";


const AIIncubatorDashboard = (props) => {
    const token = localStorage.getItem('accessToken');
    let [logCount, setLogCount] = useState();
    let [logCompletionRate, setLogCompletionRate] = useState();
    let [logCompletionTime, setLogCompletionTime] = useState();
    let [jobCount, setJobCount] = useState();

    const getLogCount = () => {
        axios.get(AI_LOGS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setLogCount(res.data));
    };

    const getJobCount = () => {
        axios.get(AI_JOB_LOGS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setJobCount(res.data));
    };

    const getLogCompletionRate = () => {
        axios.get(AI_LOGS_COMPLETION_RATE_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setLogCompletionRate(res.data));
    };

    const getLogCompletionTime = () => {
        axios.get(AI_LOGS_COMPLETION_TIME_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setLogCompletionTime(res.data));
    };

    useEffect(() => getLogCount(), []);
    useEffect(() => getJobCount(), []);
    useEffect(() => getLogCompletionRate(), []);
    useEffect(() => getLogCompletionTime(), []);

    return (
    <>
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer">
            <AISuiteHeader 
                current_user={props.current_user}
            />
            <Row>
                <Col sm="12">
                    <Row>
                        <Col>
                            <div className="Box DarkPurple">
                                <h2>Jobilla AI Incubator</h2>
                                <p>Collection of our custom AIs for intelligent automation</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <div className="Box">
                                <h2>Ad Image AI</h2>
                                <p>AI that generates ad images</p>
                                <Link to="/ai-incubator/ad-image-ai" className="btn PurpleGradient" >
                                    Continue
                                </Link>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="Box">
                                <h2>Combined AI</h2>
                                <p>Combined AI that generates campaigns</p>
                                <Link to="/ai-incubator/combined-ai" className="btn PurpleGradient" >
                                    Continue
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" sm="12">
                            <div className="Box">
                            <h4>Jobs with AI Runs</h4>
                                <span className="BoxHighlight">
                                    {jobCount || jobCount == 0 ? jobCount : (
                                        <ProgressSpinner style={{width: '36px', height: '36px', color: '#eee'}} strokeWidth="6" animationDuration="1.5s" />
                                    )}
                                </span>
                                <p>Count of distinct jobs with AI generation</p>
                            </div>
                        </Col>
                        <Col md="3" sm="12">
                            <div className="Box">
                            <h4>AI Runs</h4>
                                <span className="BoxHighlight">
                                    {logCount || logCount == 0 ? logCount : (
                                        <ProgressSpinner style={{width: '36px', height: '36px', color: '#eee'}} strokeWidth="6" animationDuration="1.5s" />
                                    )}
                                </span>
                                <p>Count of total AI functions ran</p>
                            </div>
                        </Col>
                        <Col md="3" sm="12">
                            <div className="Box">
                            <h4>AI Completion Rate</h4>
                                <span className="BoxHighlight">
                                    {logCompletionRate || logCompletionRate == 0 ? logCompletionRate : (
                                        <ProgressSpinner style={{width: '36px', height: '36px', color: '#eee'}} strokeWidth="6" animationDuration="1.5s" />
                                    )}
                                </span>
                                <p>% of AI functions successfully completing</p>
                            </div>
                        </Col>
                        <Col md="3" sm="12">
                            <div className="Box">
                            <h4>AI Run Time</h4>
                                <span className="BoxHighlight">
                                    {logCompletionTime || logCompletionTime == 0 ? (
                                        <>
                                            {logCompletionTime}
                                            s
                                        </>

                                    ) : (
                                        <ProgressSpinner style={{width: '36px', height: '36px', color: '#eee'}} strokeWidth="6" animationDuration="1.5s" />
                                    )}
                                </span>
                                <p>Average time AI functions run</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
      </Container>
    </>
    );
}

export default AIIncubatorDashboard;
