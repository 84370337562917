import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const MeridienLogo = (props) => {
    return(
    <li className="NavItem NavLogoItem">
        <Link to="/dashboard" className={props.classes}>
            {props.darkMode == 'dark' ? (
            <img src="/img/jobibrains.png" className="img-fluid logo"/>
            ) : (
            <img src="/img/jobibrains.png" className="img-fluid logo"/>
            )}
        </Link>
    </li>
);    
}

export default MeridienLogo;
