import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import FinanceHeader from "./Header";


const Invoicing = (props) => {

    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer White">
            <FinanceHeader
                current_user={props.current_user}
            />
            <Row>
                <Col>
                    <h2>
                        Invoicing
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
            </Row>
        </div>
      </Container>
    );
}

export default Invoicing;
