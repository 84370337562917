import { faDashboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const SalesHeader = (props) => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    return (
        <>
        <h1>Growth</h1>
        <Row>
            <Col>
                <div className="AdminFilters">
                    <ul className="StatusPillFilters">
                        <li className="NavItem">
                            <Link to="/growth" className={!splitLocation[2] ? "NavLink Active" : "NavLink"}>
                                <FontAwesomeIcon icon={faDashboard} />
                            </Link>
                        </li>
                        {/* <li className="NavItem">
                            <Link to="/growth/sales-leaderboard" className={splitLocation[2] == 'sales-leaderboard' ? "NavLink Active" : "NavLink"}>
                                Sales Leaderboard
                            </Link>
                        </li> */}
                        {/* <li className="NavItem">
                            <Link to="/growth/salespeople" className={splitLocation[2] == 'salespeople' ? "NavLink Active" : "NavLink"}>
                                Sales People
                            </Link>
                        </li> */}
                        <li className="NavItem">
                            <Link to="/growth/sales" className={splitLocation[2] == 'sales' ? "NavLink Active" : "NavLink"}>
                                Sales
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/growth/orders" className={splitLocation[2] == 'orders' ? "NavLink Active" : "NavLink"}>
                                Orders
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/growth/consumption" className={splitLocation[2] == 'consumption' ? "NavLink Active" : "NavLink"}>
                                Consumption
                            </Link>
                        </li>
                        {/* <li className="NavItem">
                            <Link to="/growth/products" className={splitLocation[2] == 'products' ? "NavLink Active" : "NavLink"}>
                                Products
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </Col>
        </Row>
        </>
);
}

export default SalesHeader;
