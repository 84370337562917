import { faDashboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const CustomersHeader = (props) => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    return (
        <>
        <h1>Customers &amp; Account Management</h1>
        <Row>
            <Col>
                <div className="AdminFilters">
                    <ul className="StatusPillFilters">
                        <li className="NavItem">
                            <Link to="/customers" className={!splitLocation[2] ? "NavLink Active" : "NavLink"}>
                                <FontAwesomeIcon icon={faDashboard} />
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/customers/companies" className={splitLocation[2] == 'companies' ? "NavLink Active" : "NavLink"}>
                                Companies
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/customers/users" className={splitLocation[2] == 'users' ? "NavLink Active" : "NavLink"}>
                                Users
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/customers/meetings" className={splitLocation[2] == 'meetings' ? "NavLink Active" : "NavLink"}>
                                Customer Meetings
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/customers/feedback" className={splitLocation[2] == 'feedback' ? "NavLink Active" : "NavLink"}>
                                Feedback
                            </Link>
                        </li>
                    </ul>
                </div>
            </Col>
        </Row>
        </>
);
}

export default CustomersHeader;
