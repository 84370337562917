import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

import SalesHeader from "./Header";
import BoxSales from "./Boxes/Sales";
import BoxOrderCount from "./Boxes/OrderCount";
import BoxConsumption from "./Boxes/Consumption";


const GrowthDashboard = (props) => {

    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer">
            <SalesHeader
                current_user={props.current_user}
            />
            <Row>
                <Col>
                    <h2>
                        Growth Dashboard
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col md="4" sm="12">
                    <BoxSales highlight={true} link={true} />
                </Col>
                <Col md="4" sm="12">
                    <BoxConsumption highlight={false} link={true} />
                </Col>
                <Col md="4" sm="12">
                    <BoxOrderCount link={true} time={'this-month'} />
                </Col>
                <Col md="4" sm="12">
                    <BoxOrderCount link={true} time={'today'} />
                </Col>
            </Row>
        </div>
      </Container>
    );
}

export default GrowthDashboard;
