import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStream, faUserFriends, faShoppingCart, faChartPie, faTv, faCogs } from '@fortawesome/free-solid-svg-icons'

const MeridienLink = (props) => {
    return(
        <li className="NavItem">
            <Link to={props.to} className={props.classes}>
                <span className="" data-toggle="tooltip" data-placement="right" title={props.title}>
                    <FontAwesomeIcon icon={props.icon} />
                    <span className="TooltipText">{props.title}</span>
                </span>
            </Link>
        </li>
    );
}

export default MeridienLink;
