import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Chart } from 'primereact/chart';

import axios from "axios";
import { JOB_SAMPLES_API_URL } from "../../constants";


const JobSampleDistribution = (props) => {
    const token = localStorage.getItem('accessToken');
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    let [data, setData] = useState();
    let [dataLabels, setDataLabels] = useState();
    let [dataValues, setDataValues] = useState();
    const [error, setError] = useState(null);

    const getData = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
        axios.get(JOB_SAMPLES_API_URL + 'distribution/?isco=' + group_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            setData(response.data);
            setDataLabels(response.data.map(obj => obj.label));
            setDataValues(response.data.map(obj => obj.value));
            setError(null); // Clear any previous errors
        })
    };

    useEffect(() => {
        if (dataLabels && dataValues) {
            const documentStyle = getComputedStyle(document.documentElement);
            const data = {
                labels: dataLabels,
                datasets: [
                    {
                        data: dataValues,
                        backgroundColor: ['#9a77ca', '#7854ac'],
                        hoverBackgroundColor: ['#7854ac', '#6330ae']
                    }
                ]
            };

            const options = {
                plugins: {
                    legend: {
                        display: false // This will hide the label (legend)
                    }
                }
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [dataLabels, dataValues]);

    useEffect(() => getData(), []);
    return (
        <div className="Box card flex justify-content-center">
            <h4>Candidate Count Distribution</h4>
            {dataLabels && dataValues ? (
                <Chart type="bar" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
            ): (
                <></>
            )}
        </div>
);
}

export default JobSampleDistribution;
