import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Auth from './Auth';

const GoogleAuth = () => {
  const navigate = useNavigate(); // Initialize useNavigate for redirection

  const handleLoginSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;
    console.log('Google Token:', token);
    
    try {
      // Use the googleLogin function from Auth.js to handle the login and token storage
      await Auth.googleLogin(token);
    } catch (error) {
      console.error('Google login failed:', error);
    }
  };

  return (
    <GoogleOAuthProvider clientId="1006002664293-sc204qfn61s78p052vi8o0seohivuulk.apps.googleusercontent.com">
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onError={() => {
          console.log('Google Login Failed');
        }}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleAuth;
