import { faDashboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const HiringHeader = (props) => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    return (
        <>
        <h1>Hiring</h1>
        <Row>
            <Col>
                <div className="AdminFilters">
                    <ul className="StatusPillFilters">
                        <li className="NavItem">
                            <Link to="/hire" className={!splitLocation[2] ? "NavLink Active" : "NavLink"}>
                                <FontAwesomeIcon icon={faDashboard} />
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/hire/hires" className={splitLocation[2] == 'hires' ? "NavLink Active" : "NavLink"}>
                                Hires
                            </Link>
                        </li>
                        <li className="NavItem">
                            <Link to="/hire/connections" className={splitLocation[2] == 'connections' ? "NavLink Active" : "NavLink"}>
                                ATS Connections
                            </Link>
                        </li>
                    </ul>
                </div>
            </Col>
        </Row>
        </>
);
}

export default HiringHeader;
