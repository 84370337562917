import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';

const DashboardBox = (props) => {
    return(
        <div className={props.highlight ? 'Box DarkPurple' : 'Box'}>
            <h4>{props.title}</h4>
            <span className="BoxHighlight">
                {props.value || props.value === 0 ? props.value.toLocaleString('de-DE') : (
                    <ProgressSpinner style={{width: '36px', height: '36px', color: '#eee'}} strokeWidth="6" animationDuration="1.5s" />
                )}
                {props.percentage ? ' %' : ''}
                {props.currency ? ' €' : ''}

                {props.step_conversion ? (
                <>
                    <span className='BoxHighlightSecondary'>
                    (
                    <NumericFormat
                        value={props.step_conversion * 100}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={1}
                    /> %)
                    </span>
                </>
                ) : ''}
                
            </span>
            <p>{props.description}</p>
            {props.comparison ? (
                <p><span className='StatusPill'>{props.comparison}</span></p>
            ) : ''} 
            {props.link ? (
            <Link to={props.link_url} className="btn PurpleGradient" >
                {props.link_text}
            </Link>
            ) : ''}
        </div>
    );
}

export default DashboardBox;
