import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import Moment from 'react-moment';

import { Knob } from 'primereact/knob';
import { ProgressBar } from 'primereact/progressbar';

import axios from "axios";
import { GROUPS_API_URL, PATHWAYS_API_URL } from "../../constants";
import TalentInsightsHeader from "./Header";
import AdminGroupList from "./GroupList";

const CareerPathway = (props) => {
    const token = localStorage.getItem('accessToken');
    let [pathway, setPathway] = useState();
    let [groups, setGroups] = useState([]);

    let url = window.location.pathname
    let url_components = url.split('/')
    let pathway_id = url_components[3]

    const getPathway = id => {
        axios.get(PATHWAYS_API_URL + pathway_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setPathway(response.data)
        });
    };

    const getFilteredGroups = () => {
        let complete_url = GROUPS_API_URL + '?pathway=' + pathway_id
        axios.get(complete_url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setGroups(res.data));    
    };


    useEffect(() => getPathway(), []);
    useEffect(() => getFilteredGroups(), []);

    return (
        <Container className="Main MainAdminFullWidth MainWithHero">
        <div className="Centralizer White">
        <TalentInsightsHeader 
                current_user={props.current_user}
            />
            <Row>
                <Col>
                    <div className="Box DarkPurple TalentInsightsBox">
                        <h4>Career Pathway: {pathway?.id}</h4>
                        <h2>
                            {props.current_user?.current_language_code == 'en' ? `${pathway?.name}` : ''}
                            {props.current_user?.current_language_code == 'fi' ? `${pathway?.name_fi}` : ''}
                            {props.current_user?.current_language_code == 'de' ? `${pathway?.name_de}` : ''}
                        </h2>
                        {pathway?.category ? (
                        <>
                        <Link to={`/talent-insights/clusters/${pathway.category}`} className="TalentInsightsLightLink">
                            {props.current_user?.current_language_code == 'en' ? `${pathway.category_name?.name}` : ''}
                            {props.current_user?.current_language_code == 'fi' ? `${pathway.category_name?.name_fi}` : ''}
                            {props.current_user?.current_language_code == 'de' ? `${pathway.category_name?.name_de}` : ''}
                        </Link>
                        &nbsp;
                        &nbsp;
                        </>
                        ) : ('')}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>{pathway?.description}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Related Occupation Groups</h3>
                    <AdminGroupList
                        groups={groups}
                        current_user={props.current_user}
                    />
                </Col>
            </Row>
            </div>
        </Container>
    );
};
export default CareerPathway;