import { faDashboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { NumericFormat } from 'react-number-format';

const CampaignAdMeta = (props) => {

    let ad = props.ad;
    const targeting = JSON.parse(ad?.targeting_options);
    return (
        <div key={'meta-' + ad?.id}>
            <h4>Location</h4>
            {targeting?.cities?.map(city => (
                <div key={city.id}>
                    <p>{city?.name} ({city?.country?.name})</p>
                </div>
            ))}
            <h4>Interests</h4>
            {targeting?.interests?.map(interest => (
                <div key={interest.id}>
                    <p>{interest?.name}</p>
                </div>
            ))}

        </div>
);
}

export default CampaignAdMeta;
