import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import Moment from 'react-moment';

const AdminSkillList = (props) => {
    let skills = props.skills?.filter(skill => skill.name?.toLowerCase().includes(props.skill_search?.toLowerCase()));
    return (
      <div className="TableContainer">
        <Table className="WhiteTable AdminAnalysisTable">
            <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Type</th>
            </tr>
            </thead>
            <tbody>
            {!skills || skills?.length <= 0 ? (
                <tr>
                <td colSpan="1">
                    <b>Ops, no skills here yet</b>
                </td>
                </tr>
            ) : (
                skills?.map(skill => (
                <tr key={skill.id}>
                    <td width="5%">
                        {skill.id}
                    </td>
                    <td>
                        <Link to={`/talent-insights/skills/${skill.id}`}>
                            {skill.name}
                        </Link>
                    </td>
                    <td>
                        {skill.skill_type}
                    </td>
                </tr>
                ))
            )}
            </tbody>
        </Table>

      </div>
    );
}

export default AdminSkillList;
