import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table } from "reactstrap";
import { NumericFormat } from 'react-number-format';

import { Chart } from 'primereact/chart';

import axios from "axios";
import { GIANT_SOCIALMEDIA_APPS_API_URL } from "../../../constants";


const GiantSocialMediaApps = (props) => {
    const token = localStorage.getItem('accessToken');
    let [data, setData] = useState();

    const getData = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
        axios.get(GIANT_SOCIALMEDIA_APPS_API_URL + group_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setData(response.data);
        });
    };

    useEffect(() => getData(), []);

    return (
        <div className="TalentInsightsContentBox">
            <h4 className="TalentInsightBoxCentered">Favorite Social Media Apps</h4>
            <Table className="">
                <thead className="TalentInsightsTableHead">
                </thead>
                <tbody>
                {!data || data?.length <= 0 ? (
                    <tr>
                        <td colSpan="2">
                            <b>Ops, no data here yet</b>
                        </td>
                    </tr>
                ) : (
                    data?.map(d => (
                    <tr key={d.id}>
                        <td width="10%">
                            {d.rank}
                        </td>
                        <td width="90%">
                            {d.label}
                        </td>
                    </tr>
                    ))
                )}
                </tbody>
            </Table>
            <span className="TalentInsightsBoxDescription">
                The social media and apps that are most used by the {props.group?.name} when looking for a (new) job.
                Data source Intelligence Group.
            </span>
        </div>
);
}

export default GiantSocialMediaApps;
