import React, { Component, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';

import axios from "axios";
import { AI_TARGETING_INTERESTS_API_URL } from "../../constants";

import AISuiteHeader from "./Header";
import AIIncubatorJobTitle from "./JobDetails";
import AIIncubatorJobDetails from "./JobDetails";
import { ProgressBar } from "primereact/progressbar";


const TargetingInterestAI = forwardRef((props, ref) => {
    const token = localStorage.getItem('accessToken');
    const [targeting, setTargeting] = useState();
    const [seconds, setSeconds] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const runTargetingAI = (job_id) => {
        console.log('Targeting AI running', job_id)
        getTargeting(job_id);
        setIsLoading(true);
        setSeconds(0);
        const interval = setInterval(() => {
            setProgress(prev => {
                if (prev < 100) {
                    return prev + 1;
                } else {
                    clearInterval(interval); // Stop when it reaches 100%
                    return prev; // Return 100% on completion
                }
            });
        }, 75);

        return () => clearInterval(interval); // Cleanup on unmount
    };

    useImperativeHandle(ref, () => ({
        runTargetingAI: runTargetingAI, // Expose the function
    }));

    const getTargeting = (job_id) => {
        const payload = {
            job_id: job_id, 
        };
        axios.post(AI_TARGETING_INTERESTS_API_URL, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            setTargeting(response.data);
        })        
        .catch((error) => {
            console.error('Error fetching targeting:', error);
        });
    };
    
    return (
        <div className="Content">
            <Row>
                <Col>
                    {!targeting ? (
                    <ProgressBar value={progress} />
                    ) : (
                    <div className="WelcomeContent">
                        {targeting?.map((interest, index) => (
                        <div key={index}>
                            <h4>{interest.interest}</h4>
                            <p>{interest.reasoning}</p>
                        </div>
                        ))}
                    </div>
                    )}
                </Col>
            </Row>
        </div>
    );
})

export default TargetingInterestAI;
