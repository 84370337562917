import React, { Component, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { AI_QUESTIONS_API_URL } from "../../constants";

import { ProgressBar } from "primereact/progressbar";


const QuestionnaireAI = forwardRef((props, ref) => {
    const token = localStorage.getItem('accessToken');
    const [questionnaire, setQuestionnaire] = useState([]);
    const [seconds, setSeconds] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const runQuestionnaireAI = (job_id) => {
        console.log('Questionnaire AI running', job_id)
        getQuestionnaire(job_id);
        setIsLoading(true);
        setSeconds(0); // Reset seconds
        const interval = setInterval(() => {
            setProgress(prev => {
                if (prev < 100) {
                    return prev + 1; // Increase progress by 1%
                } else {
                    clearInterval(interval); // Stop when it reaches 100%
                    return prev; // Return 100% on completion
                }
            });
        }, 200);

        return () => clearInterval(interval); // Cleanup on unmount
    };
    
    useImperativeHandle(ref, () => ({
        runQuestionnaireAI: runQuestionnaireAI, // Expose the function
    }));

    const getQuestionnaire = (job_id) => {
        const payload = {
            job_id: job_id, 
        };
        axios.post(AI_QUESTIONS_API_URL, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            setQuestionnaire(response.data);
        })        
        .catch((error) => {
            console.error('Error fetching questionnaire:', error);
        });
    };
    
    useEffect(() => {
        let timer;
        if (isLoading) {
          timer = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds + 1);
          }, 1000);
        }
        
        // Clear interval on component unmount or when loading ends
        return () => clearInterval(timer);
    }, [isLoading]);

    useEffect(() => {
        if (seconds >= 30) {
          setIsLoading(false);
        }
    }, [seconds]);

    console.log(questionnaire)

    return (
        <div className="Content">
            <Row>
                <Col>
                    {questionnaire.length <= 0 ? (
                    <ProgressBar value={progress} />
                    ) : (
                    <div className="CandidateFunnel">
                        <div className="CandidateFunnelForm" id="form">
                                <>
                                    {/* <div className="text-center">
                                        <ProgressSpinner />
                                        <h4>{seconds}/10-20 seconds</h4>
                                    </div> */}
                                </>
                            <ul>
                                {questionnaire?.map((question, index) => (
                                        <fieldset key={index}>
                                        <div className="QuestionTitleContainer">
                                            <span className="QuestionOrderId">{index + 1}</span>
                                            <legend>
                                                <span>{question?.question}</span>
                                                <span className="RequiredLabel">Required</span>
                                            </legend>
                                        </div>
                                        { question.answer_options.map((option, option_index) => (
                                            <div className="Option Radio" key={option_index}>
                                                <label className="OptionLabel">
                                                    {option.name}
                                                    <input 
                                                        type="radio" 
                                                        id={option_index}
                                                        name={index}
                                                        value={option_index} 
                                                        // onClick={(e) => onOptionChange(question, option)}
                                                    />
                                                    <span className="Checkmark"></span>
                                                </label>
                                            </div>
                                        ))}
                                    </fieldset>
                                ))}
                            </ul>
                        </div>
                    </div>
                    )}
                </Col>
            </Row>
        </div>
    );
})

export default QuestionnaireAI;
