import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { 
    CANDIDATES_COUNT_API_URL,
    CANDIDATES_DATA_API_URL,
} from "../../constants";

import { Chart } from 'primereact/chart';

import CampaignsHeader from "./Header";
import { ProgressSpinner } from "primereact/progressspinner";
import DashboardBox from "../Global/Box";

const Candidates = (props) => {
    const token = localStorage.getItem('accessToken');
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    let [data, setData] = useState();
    let [dataLabels, setDataLabels] = useState();
    let [dataValues, setDataValues] = useState();
    let [candidateCountToday, setCandidateCountToday] = useState();
    let [candidateCountThisMonth, setCandidateCountThisMonth] = useState();
    const [error, setError] = useState(null);

    const getCandidateCountToday = () => {
        axios.get(CANDIDATES_COUNT_API_URL + 'today/', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCandidateCountToday(res.data));
    };

    const getCandidateCountThisMonth = () => {
        axios.get(CANDIDATES_COUNT_API_URL + 'this-month/', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCandidateCountThisMonth(res.data));
    };

    const getData = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = 1120 // Remove
        axios.get(CANDIDATES_DATA_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            setData(response.data);
            setDataLabels(response.data.map(obj => obj.time));
            setDataValues(response.data.map(obj => obj.value));
            setError(null); // Clear any previous errors
        })
    };

    useEffect(() => {
        if (dataLabels && dataValues) {
            const documentStyle = getComputedStyle(document.documentElement);
            const data = {
                labels: dataLabels,
                datasets: [
                    {
                        data: dataValues,
                        backgroundColor: ['#9a77ca'],
                        hoverBackgroundColor: ['#7854ac']
                    }
                ]
            };

            const options = {
                plugins: {
                    legend: {
                        display: false // This will hide the label (legend)
                    }
                }
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [dataLabels, dataValues]);

    useEffect(() => getData(), []);
    useEffect(() => getCandidateCountThisMonth(), []);
    useEffect(() => getCandidateCountToday(), []);

    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer">
            <CampaignsHeader
                current_user={props.current_user}
            />
            <Row>
                <Col>
                    <h2>
                        Candidates
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col md="4" sm="12">
                    <DashboardBox 
                        highlight={true}
                        title={'Candidates This Month'}
                        value={candidateCountThisMonth}
                        description={'Total candidates acquired this month'}
                    />
                </Col>
                 <Col md="4" sm="12">
                    <DashboardBox 
                        highlight={false}
                        title={'Candidates Today'}
                        value={candidateCountToday}
                        description={'Total candidates acquired today'}
                    />
                </Col>
           </Row>
            <Row className="ContentWhite">
                <Col>
                {dataLabels && dataValues ? (
                    <Chart type="bar" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
                ): (
                    <div className="text-center">
                        <ProgressSpinner />
                    </div>
                )}
                </Col>
        </Row>
        </div>
      </Container>
    );
}

export default Candidates;
