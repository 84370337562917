import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link, useSearchParams} from "react-router-dom";
import axios from "axios";
import { OCCUPATIONS_API_URL } from "../../constants";
import AdminOccupationList from "./OccupationList";
import TalentInsightsHeader from "./Header";


const Occupations = (props) => {
    const token = localStorage.getItem('accessToken');
    let [occupations, setOccupations] = useState([]);
    let [occupation_search, setOccupationSearch] = useState('');
    let [page, setPage] = useState(1);

    const handleOccupationSearch = (event) => {
        setOccupationSearch(event.target.value);
        getFilteredOccupations(event.target.value);
    };

    const handlePagination = (page) => {
        setPage(page);
        getFilteredOccupations(occupation_search);
    };

    const getOccupations = () => {
        axios.get(OCCUPATIONS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setOccupations(res.data));
    };

    const getFilteredOccupations = (occupation_search) => {
        axios.get(OCCUPATIONS_API_URL + '?name=' + occupation_search + '&page=' + page, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setOccupations(res.data));
    };

    useEffect(() => getOccupations(), []);

    return (
      <Container className="Main MainAdminFullWidth MainWithHero">
        <div className="Centralizer White">
        <TalentInsightsHeader 
            current_user={props.current_user}
        />
        <Row>
            <Col>
                <div className="Box DarkPurple TalentInsightsBox">
                    <h2>
                        {props.current_user?.current_language_code == 'en' ? 'Job Titles' : ''}
                        {props.current_user?.current_language_code == 'fi' ? 'Ammatit' : ''}
                        {props.current_user?.current_language_code == 'de' ? 'Job Titles' : ''}                        
                    </h2>
                    <div className="RecruitmentSearch AdminFilter">
                        <input className="TextInput" placeholder="Search" value={occupation_search} onChange={handleOccupationSearch}/>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
          <Col>
            <AdminOccupationList
                occupations={occupations}
                occupation_search={occupation_search}
                handlePagination={handlePagination}
                current_user={props.current_user}
            />
          </Col>
        </Row>
        </div>
      </Container>
    );
}

export default Occupations;
