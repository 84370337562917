import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { Skeleton } from 'primereact/skeleton';
import Moment from 'react-moment';

const OccupationList = (props) => {
    let occupations = props.occupations;
    return (
    <>
      <div className="TableContainer">
        <Table className="WhiteTable AdminAnalysisTable">
            <thead>
            <tr>
                <th>Name</th>
                <th>Group</th>
            </tr>
            </thead>
            <tbody>
            {!occupations || occupations?.length <= 0 ? (
                <tr>
                <td colSpan="2">
                    {!occupations <= 0 ? (
                        <Skeleton className="mb-2"></Skeleton>                    
                    ) : (
                        <b>Ops, no occupations here yet</b>
                    )}                        
                </td>
                </tr>
            ) : (
                occupations?.map(occupation => (
                <tr key={occupation.id}>
                    {/* <td width="5%">
                        {occupation.code}
                    </td> */}
                    <td width="60%">
                        {props.forceLink ? (
                        <a href={`/talent-insights/groups/${occupation.isco_group_code}/${occupation.code}`}>
                            {props.current_user?.current_language_code == 'en' ? `${occupation?.name}` : ''}
                            {props.current_user?.current_language_code == 'fi' ? `${occupation?.name_fi}` : ''}
                            {props.current_user?.current_language_code == 'de' ? `${occupation?.name_de}` : ''}
                        </a>
                        ) : (
                        <Link to={`/talent-insights/groups/${occupation.isco_group_code}/${occupation.code}`}>
                            {props.current_user?.current_language_code == 'en' ? `${occupation?.name}` : ''}
                            {props.current_user?.current_language_code == 'fi' ? `${occupation?.name_fi}` : ''}
                            {props.current_user?.current_language_code == 'de' ? `${occupation?.name_de}` : ''}
                        </Link>
                        )}
                    </td>
                    <td width="40%">
                        {props.current_user?.current_language_code == 'en' ? `${occupation?.group_name?.name}` : ''}
                        {props.current_user?.current_language_code == 'fi' ? `${occupation?.group_name?.name_fi}` : ''}
                        {props.current_user?.current_language_code == 'de' ? `${occupation?.group_name?.name_de}` : ''}
                    </td>
                </tr>
                ))
            )}
            </tbody>
        </Table>
      </div>
    </>
    );
}

export default OccupationList;
