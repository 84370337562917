import React, { useEffect, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const Logout = () => {
    const { logout } = useContext(AuthContext);

    useEffect(() => {
        // Call the logout function from AuthContext when the component is mounted
        logout();
    }, [logout]);

    // Redirect to the login page after logout
    return <Navigate to="/" />;
};

export default Logout;
