import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import './css/App.css';
import './css/DarkMode.css';
import './css/Funnel.css';
import './css/Responsive.css';

import App from './App';
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";                                       

import * as serviceWorker from './serviceWorker';
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter } from 'react-router-dom';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';

ReactDOM.render(<BrowserRouter><AuthProvider><PrimeReactProvider><App /></PrimeReactProvider></AuthProvider></BrowserRouter>, document.getElementById('app'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
