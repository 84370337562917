import { useState, useContext } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import AuthContext from "../context/AuthContext";

function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const { registerUser } = useContext(AuthContext);

  const handleSubmit = async e => {
    e.preventDefault();
    registerUser(username, password, password2);
  };

  return (
    <Container className="Main MainWithHero">
        <h1>Register </h1>
        <Row>
            <Col>
                <form onSubmit={handleSubmit}>
                    <div>
                    <label htmlFor="username">Username</label>
                    <input
                        className="TextInput"
                        type="text"
                        id="username"
                        onChange={e => setUsername(e.target.value)}
                        placeholder="Username"
                        required
                    />
                    </div>
                    <div>
                    <label htmlFor="password">Password</label>
                    <input
                        className="TextInput"
                        type="password"
                        id="password"
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Password"
                        required
                    />
                    </div>
                    <div>
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input
                        className="TextInput"
                        type="password"
                        id="confirm-password"
                        onChange={e => setPassword2(e.target.value)}
                        placeholder="Confirm Password"
                        required
                    />
                    <p>{password2 !== password ? "Passwords do not match" : ""}</p>
                    </div>
                    <button className="btn btn-primary">Register</button>
                </form>
            </Col>
        </Row>
    </Container>
  );
}

export default Register;
