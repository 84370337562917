import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

import axios from "axios";
import { 
    JOBS_WITH_TARGET_CAMPAIGNS_COMPLETED_THIS_MONTH_COUNT_API_URL, 
    CAMPAIGN_SUCCESS_COMPLETED_THIS_MONTH_API_URL,
 } from "../../constants";

import CampaignsHeader from "./Header";
import { ProgressSpinner } from "primereact/progressspinner";
import DashboardBox from "../Global/Box";

const CampaignSuccess = (props) => {
    const token = localStorage.getItem('accessToken');
    let [campaignsCompletedThisMonth, setCampaignsCompletedThisMonth] = useState();
    let [campaignSuccessCompletedThisMonth, setCampaignSuccessCompletedThisMonth] = useState();

    const getCampaignsCompletedThisMonth = () => {
        axios.get(JOBS_WITH_TARGET_CAMPAIGNS_COMPLETED_THIS_MONTH_COUNT_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCampaignsCompletedThisMonth(res.data));
    };

    const getCampaignSuccessCompletedThisMonth = () => {
        axios.get(CAMPAIGN_SUCCESS_COMPLETED_THIS_MONTH_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCampaignSuccessCompletedThisMonth(res.data));
    };

    useEffect(() => getCampaignsCompletedThisMonth(), []);
    useEffect(() => getCampaignSuccessCompletedThisMonth(), []);

    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer">
            <CampaignsHeader
                current_user={props.current_user}
            />
            <Row>
                <Col>
                    <h2>
                        Campaign Success
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col md="4" sm="12">
                    <DashboardBox
                        highlight={true}
                        percentage={true}
                        title={'Campaign Success % This Month'}
                        value={campaignSuccessCompletedThisMonth}
                        description={'% of campaigns completed this month w. 10+ candidates'}
                    />
                </Col>
                <Col md="4" sm="12">
                    <DashboardBox
                        highlight={false}
                        title={'Campaigns Completed This Month'}
                        value={campaignsCompletedThisMonth}
                        description={'Count of campaigns ended this month'}
                    />
                </Col>
            </Row>
            <Row className="ContentWhite">
                <Col>
                </Col>
            </Row>
        </div>
      </Container>
    );
}

export default CampaignSuccess;
