import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import Moment from 'react-moment';

const AdminJobSampleList = (props) => {
    let job_samples = props.job_samples;

    console.log(job_samples.length)

    return (
    <>
      <div className="TableContainer">
        <Table className="WhiteTable AdminAnalysisTable">
            <thead>
            <tr>
                <th>Job</th>
                <th className="text-right">Candidate Count</th>
            </tr>
            </thead>
            <tbody>
            {!job_samples || job_samples?.length <= 0 ? (
                <tr>
                <td colSpan="1">
                    <b>Ops, no job samples here yet</b>
                </td>
                </tr>
            ) : (
                job_samples?.map(job => (
                <tr key={job.id}>
                    <td width="50%">
                        <a href={'https://tas.app.jobilla.com/c/' + `${job.company_id}` + '/jobs/' + `${job.id}` } target="_blank">
                            {job.name}
                        </a>
                    </td>
                    <td width="50%" align="right">
                        {job.candidate_count}
                    </td>
                </tr>
                ))
            )}
            </tbody>
        </Table>
      </div>
    </>
    );
}

export default AdminJobSampleList;
