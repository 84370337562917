import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import SalesHeader from "./Header";
import BoxConsumption from "./Boxes/Consumption";


const Consumption = (props) => {

    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer">
        <SalesHeader
            current_user={props.current_user}
        />
        <Row>
            <Col>
                <h2>
                    Consumption
                </h2>
            </Col>
        </Row>
        <Row>
            <Col md="4" sm="12">
                <BoxConsumption />
            </Col>
        </Row>
        <Row className="ContentWhite">
          <Col>
          </Col>
        </Row>
        </div>
      </Container>
    );
}

export default Consumption;
