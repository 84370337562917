import React, { Component, useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

import axios from "axios";
import { AI_JOBS_API_URL } from "../../constants";

import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from 'primereact/dropdown';

import AISuiteHeader from "./Header";
import AIIncubatorJobDetails from "./JobDetails";
import WelcomeAI from "./Welcome";
import TargetingInterestAI from "./TargetingInterest";
import QuestionnaireAI from "./Questionnaire";
import CategoriserAI from "./Categoriser";
import AttributeAI from "./Attribute";
import PredictAI from "./Predict";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";


const CombinedAIIncubator = (props) => {
    const token = localStorage.getItem('accessToken');
    const [job, setJob] = useState(null);
    const [jobs, setJobs] = useState(null);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const childWelcomeRef = useRef();
    const childQuestionnaireRef = useRef();
    const childTargetingRef = useRef();
    const [progress, setProgress] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const handleAllAI = () => {
        if (job) {
            handleWelcomeAI(job.id);
            handleQuestionnaireAI(job.id);
            handleTargetingAI(job.id);    
        }
    };

    const handleWelcomeAI = (job_id) => {
        if (job && childWelcomeRef.current) {
            childWelcomeRef.current.runWelcomeAI(job.id);
        }
    };

    const handleQuestionnaireAI = (job_id) => {
        if (job && childQuestionnaireRef.current) {
            childQuestionnaireRef.current.runQuestionnaireAI(job.id);
        }
    };

    const handleTargetingAI = (job_id) => {
        if (job && childTargetingRef.current) {
            childTargetingRef.current.runTargetingAI(job.id);
        }
    };

    // const handleJobSearch = async (searchValue) => {
    //     setSearchTerm(searchValue);
    //     if (searchValue) {
    //         // Perform a database search here (replace this with your API call)
    //         const response = await fetch(`your-api-endpoint?search=${searchValue}`);
    //         const data = await response.json();
    //         setJobs(data); // Update the jobs based on the response
    //     } else {
    //         setJobs([]); // Clear the jobs if the search value is empty
    //     }
    // };

    const getJobs = () => {
        axios.get(AI_JOBS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setJobs(response.data);
        });
    };

    // const getFilteredJobs = (job_search) => {
    //     axios.get(AI_JOBS_API_URL + '?s=' + job_search, {
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //         },
    //     }).then((response) => {
    //         setJobs(response.data);
    //     });
    // };

    useEffect(() => getJobs(), []);

    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer">
            <AISuiteHeader
                current_user={props.current_user}
            />
        </div>
        <div className="Content">
            <Row>
                <Col sm="12">
                    <div className="alert alert-warning">
                        One of the bots crash roughly 30% of the time (need to improve prompting). If your page crashes, just refresh it Cmd + R.
                    </div>
                </Col>
                <Col sm="5">
                    <div className="IncubatorInputBox">
                        <div className="card flex justify-content-center">
                            <Dropdown 
                                value={job} 
                                onChange={(e) => setJob(e.value)} 
                                options={jobs} 
                                optionLabel="name" 
                                placeholder="Select a Job" 
                                filter 
                                // filterValue={searchTerm} 
                                // onFilter={(e) => handleJobSearch(e.target?.value)} 
                                className="w-full md:w-14rem" 
                            />
                        </div>    
                    </div>
                    <br/>
                    {job ? (
                    <div className="IncubatorInputBox">
                        <table className="table no-borders">
                            <tbody>
                                <tr>
                                    <th width="30%">
                                        Job
                                    </th>
                                    <td className="text-right">
                                        {job?.name}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Job ID
                                    </th>
                                    <td className="text-right">
                                        {job?.id}
                                        &nbsp;
                                        <a href={'https://tas.app.jobilla.com/c/'  + job?.company + '/jobs/' + job?.id + '/settings/details'} target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Company ID
                                    </th>
                                    <td className="text-right">
                                        {job?.company}
                                        &nbsp;
                                        <a href={'https://tas.app.jobilla.com/c/'  + job?.company} target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    ) : ('')}
                    <br/>
                    {job ? (
                    <div className="IncubatorInputBox">
                        <table className="table no-borders">
                            <tbody>
                                <tr>
                                    <th>
                                        Combined AI
                                    </th>
                                    <td className="text-right">
                                        <span className="btn btn-primary" onClick={handleAllAI}>Run All AIs</span>
                                    </td>
                                </tr>
                                <tr className="hide">
                                    <th>
                                        Categoriser AI
                                    </th>
                                    <td className="text-right">
                                        -
                                        {/* <span className="IncubatorLink" onClick={handleWelcomeAI}>Run Welcome AI</span> */}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Welcome AI
                                    </th>
                                    <td className="text-right">
                                        <span className="IncubatorLink" onClick={handleWelcomeAI}>Run Welcome AI</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Questionnaire AI
                                    </th>
                                    <td className="text-right">
                                        <span className="IncubatorLink" onClick={handleQuestionnaireAI}>Run Questionnaire AI</span>
                                    </td>
                                </tr>
                                {/* <tr>
                                    <th>
                                        Attributes AI
                                    </th>
                                    <td className="text-right">
                                    </td>
                                </tr> */}
                                <tr>
                                    <th>
                                        Targeting Interest AI
                                    </th>
                                    <td className="text-right">
                                        <span className="IncubatorLink" onClick={handleTargetingAI}>Run Targeting AI</span>
                                    </td>
                                </tr>
                                <tr className="hide">
                                    <th>
                                        Predict AI
                                    </th>
                                    <td className="text-right">
                                        -
                                        {/* <span className="IncubatorLink" onClick={handleQuestionnaireAI}>Run Questionnaire AI</span> */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    ) : ('')}
                </Col>
                <Col sm="7">
                    <div className="IncubatorInputBox">

                        <table className="table IncubatorOutputTable">
                            <tbody>
                                {/* <tr>
                                    <th className="IncubatorOutputHeader">
                                        <h3>Categoriser AI</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <CategoriserAI />
                                    </td>
                                </tr> */}
                                <tr>
                                    <th>
                                        <h3>Welcome AI</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <td id="copy__welcome">
                                        <WelcomeAI 
                                            ref={childWelcomeRef}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <h3>Questionnaire AI</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <td id="copy__questionnaire">
                                        <QuestionnaireAI 
                                            ref={childQuestionnaireRef}
                                        />
                                    </td>
                                </tr>
                                {/* <tr>
                                    <th>
                                        <h3>Attributes AI</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <AttributeAI 
                                            // ref={childQuestionnaireRef}
                                        />
                                    </td>
                                </tr> */}
                                <tr>
                                    <th>
                                        <h3>Targeting Interest AI</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <td id="copy__targeting_interests">
                                        <TargetingInterestAI 
                                            ref={childTargetingRef}
                                        />
                                    </td>
                                </tr>
                                {/* <tr>
                                    <th>
                                        <h3>Predict AI</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <td id="copy__predict">
                                        <PredictAI 
                                            // ref={childTargetingRef}
                                        />
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </div>
      </Container>
    );
}

export default CombinedAIIncubator;
