import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";


const Dashboard = (props) => {

    return (
    <>
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer">
            <h1>Jobilla Brains</h1>
            <Row>
                <Col>
                    <div className="Box DarkPurple">
                        <h2>Jobilla Brains &trade;</h2>
                        <p>Your go-to place when you need insights</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <Row>
                        <Col md="6" sm="12">
                            <div className="Box">
                                <h2>Talent Insights AI &trade;</h2>
                                <p>Accurate up-to-date target group insights</p>
                                <Link to="/talent-insights" className="btn PurpleGradient" >
                                    Continue
                                </Link>
                            </div>
                        </Col>
                        <Col md="6" sm="12">
                            <div className="Box text-center">
                                <h2>Growth</h2>
                                <p><a href="https://diilit.jobilla.com" target="_blank">diilit.jobilla.com</a> will soon merge here!</p>
                                <Link to="/growth" className="btn PurpleGradient" >
                                    Soon available
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md="6" sm="12">
                            <div className="Box text-center">
                                <h2>Customers</h2>
                                <p>Customers &amp; account management</p>
                                <Link to="/customers" className="btn PurpleGradient" >
                                    Let's see if even if this is updated
                                </Link>
                            </div>
                        </Col>
                        <Col md="6" sm="12">
                            <div className="Box text-center">
                                <h2>Campaign Performance</h2>
                                <p>Campaign performance</p>
                                <Link to="/campaigns" className="btn PurpleGradient" >
                                    Continue
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" sm="12">
                            <div className="Box text-center">
                                <h2>Hiring</h2>
                                <p>Hiring success</p>
                                <Link to="/hire" className="btn PurpleGradient" >
                                    Continue
                                </Link>
                            </div>
                        </Col>
                        <Col md="6" sm="12">
                            <div className="Box text-center">
                                <h2>Finance</h2>
                                <p>Invoicing &amp; other finance data</p>
                                <Link to="/finance" className="btn PurpleGradient" >
                                    Continue
                                </Link>
                            </div>
                        </Col>
                    </Row> */}
                </Col>
                {/* <Col sm="4">
                    <Row>
                        <Col md="6" sm="12">
                            <div className="Box">
                                <img src="/img/slite_operating_manual.png" className="img-fluid"/>
                                <h2>Knowledge Base</h2>
                                <p>This operating manual described the set of practices on how to run Jobilla's operational business unit.</p>
                                <p>The operating manual is designed to help all of our personnel to become better at their jobs by knowing the best practices and standards and understanding the underlying reasoning behind all those practices. </p>
                                <a href="https://jobilla.slite.com/app/channels/3v1F8ZriqxE_AI" target="_blank" className="btn PurpleGradient" >
                                    Continue
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Col> */}
            </Row>
        </div>
      </Container>
    </>
    );
}

export default Dashboard;
