import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

import { ProgressSpinner } from "primereact/progressspinner";
import { Slider } from "primereact/slider";

import axios from "axios";
import { 
    JOBS_WITH_TARGET_CAMPAIGNS_ACTIVE_COUNT_API_URL, 
    JOBS_WITH_TARGET_CAMPAIGNS_STARTED_TODAY_COUNT_API_URL,
    TARGET_CAMPAIGNS_API_URL,
    TARGET_CAMPAIGNS_GRID_API_URL
} from "../../constants";

import CampaignsHeader from "./Header";
import CampaignList from "./CampaignList";
import DashboardBox from "../Global/Box";
import CampaignGrid from "./CampaignGrid";


const Campaigns = (props) => {
    const token = localStorage.getItem('accessToken');
    let [activeCampaignCount, setActiveCampaignCount] = useState();
    let [campaigns, setCampaigns] = useState([]);
    let [campaignSearch, setCampaignSearch] = useState('');
    let [campaignsStartedToday, setCampaignsStartedToday] = useState();
    let [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [debouncedTerm, setDebouncedTerm] = useState(campaignSearch);
    const [range, setRange] = useState([0,100]);

    const handleCampaignSearch = (event) => {
        setCampaignSearch(event.target.value);
        getFilteredCampaigns(event.target.value);
    };

    const handlePagination = (page) => {
        setPage(page);
        getFilteredCampaigns(campaignSearch);
    };

    const getActiveCampaignCount = () => {
        axios.get(JOBS_WITH_TARGET_CAMPAIGNS_ACTIVE_COUNT_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setActiveCampaignCount(res.data));
    };

    const getCampaignsStartedToday = () => {
        axios.get(JOBS_WITH_TARGET_CAMPAIGNS_STARTED_TODAY_COUNT_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCampaignsStartedToday(res.data));
    };

    const getCampaigns = () => {
        axios.get(TARGET_CAMPAIGNS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCampaigns(res.data));
    };

    function safeStringify(obj) {
        const seen = new WeakSet();
        return JSON.stringify(obj, (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return; // Circular reference found, discard key
                }
                seen.add(value);
            }
            return value;
        });
    }

    function buildUrl(baseUrl, params) {
        const url = new URL(baseUrl);
    
        for (const [key, value] of Object.entries(params)) {
            if (value !== undefined && value !== null && value !== '') {
                // Check if the value is an object
                if (typeof value === 'object') {
                    // Safely stringify the object to handle circular references
                    url.searchParams.append(key, safeStringify(value));
                } else {
                    url.searchParams.append(key, value);
                }
            }
        }
    
        return url.toString();
    }
        
    const getFilteredCampaigns = () => {
        const params = {
            s: campaignSearch,
            candidates_min: range[0],
            candidates_max: range[1],
            page: page
        };
        const complete_url = buildUrl(TARGET_CAMPAIGNS_GRID_API_URL, params);
        console.log(complete_url)
        axios.get(complete_url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => {
            setCampaigns(res.data);
            setIsLoading(false);
        })
    };

    // Update debouncedTerm after a delay whenever searchTerm changes
    useEffect(() => {
        const handler = setTimeout(() => {
        setDebouncedTerm(campaignSearch);
        }, 500); // Wait 500ms after the user stops typing

        // Cleanup the timeout if the user types within the 500ms
        return () => {
        clearTimeout(handler);
        };
    }, [campaignSearch]);

    // This effect triggers the search whenever debouncedTerm changes
    useEffect(() => {
        if (debouncedTerm) {
            getFilteredCampaigns(debouncedTerm);
        }
    }, [debouncedTerm]);

    useEffect(() => getActiveCampaignCount(), []);
    useEffect(() => getCampaignsStartedToday(), []);
    useEffect(() => getFilteredCampaigns(), []);

    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer">
            <CampaignsHeader
                current_user={props.current_user}
            />
            <Row>
                <Col>
                    <h2>
                        Campaigns
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col md="4" sm="12">
                    <DashboardBox
                        highlight={true}
                        title={'Active Campaigns'}
                        value={activeCampaignCount}
                        description={'Jobs with currently active campaigns'}
                    />
                </Col>
                <Col md="4" sm="12">
                    <DashboardBox
                        highlight={false}
                        title={'Campaigns Started Today'}
                        value={campaignsStartedToday}
                        description={'Count of campaigns started today'}
                    />
                </Col>
            </Row>
        </div>
        <div className="Content">
            <Row className="Content">
                <Col sm="2">
                    <div className="RecruitmentSearch AdminFilter">
                        <label className="FilterLabel">Search</label>
                        <input className="TextInput" placeholder="Search" value={campaignSearch} onChange={(e) => setCampaignSearch(e.target.value)}/>
                    </div>
                </Col>
                <Col sm="2">
                    <div className="RecruitmentSearch AdminFilter">
                        <label className="FilterLabel">Candidates</label>
                        <p>{range[0]} - {range[1] < 100 ? range[1] : range[1] + '+'}</p>
                        <Slider value={range} onChange={(e) => setRange(e.value)} className="w-14rem" range />
                    </div>
                </Col>
                <Col sm="2">
                    <span className="btn btn-primary" onClick={(e) => (getFilteredCampaigns(), setCampaigns([]), setIsLoading(true))}>
                        Search
                        {isLoading ? (
                            <ProgressSpinner style={{width: '20px', height: '20px'}}  />
                        ) : ''}
                    </span>
                </Col>
            </Row>
            <Row className="Content">
                <Col>
                    <CampaignGrid 
                        current_user={props.current_user}
                        campaigns={campaigns}
                        isLoading={isLoading}
                    />
                </Col>
            </Row>
        </div>
      </Container>
    );
}

export default Campaigns;
