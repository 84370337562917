import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import Moment from 'react-moment';

import axios from "axios";
import { SKILLS_API_URL } from "../../constants";
import TalentInsightsHeader from "./Header";

const Skill = (props) => {
    const token = localStorage.getItem('accessToken');
    let [skill, setSkill] = useState();

    const getSkill = id => {
        axios.get(SKILLS_API_URL + id).then((response) => {
            setSkill(response.data)
        });
    };

    let url = window.location.pathname
    let url_components = url.split('/')
    let skill_id = url_components[4]
    useEffect(() => getSkill(skill_id), []);

    return (
        <Container className="Main MainAdminFullWidth MainWithHero">
            <div className="Centralizer White">
            <TalentInsightsHeader 
                current_user={props.current_user}
            />
            <Row>
                <Col>
                    <div className="Box DarkPurple TalentInsightsBox">
                        <h2>Skill: {skill?.name}</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Related Skills</h3>
                    <div className="TableContainer">
                        <Table className="WhiteTable AdminAnalysisTable">
                            <thead>
                            <tr>
                                <th>Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!skill?.related_skills || skill?.related_skills?.length <= 0 ? (
                                <tr>
                                <td colSpan="1">
                                    <b>Ops, no related skills here yet</b>
                                </td>
                                </tr>
                            ) : (
                                skill?.related_skills?.map(related_skill => (
                                <tr key={related_skill.id}>
                                    <td>
                                        <Link to={`/talent-insights/skills/${related_skill.id}`} onClick={() => getSkill(related_skill.id)}>
                                            {related_skill.name}
                                        </Link>
                                    </td>
                                    <td>
                                        {related_skill.relation_type}
                                    </td>
                                </tr>
                                ))
                            )}
                            </tbody>
                        </Table>

                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Related Occupations</h3>
                    <div className="TableContainer">
                        <Table className="WhiteTable AdminAnalysisTable">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Isco</th>
                                <th>Relation Type</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!skill?.related_occupations || skill?.related_occupations?.length <= 0 ? (
                                <tr>
                                <td colSpan="1">
                                    <b>Ops, no related occupations here yet</b>
                                </td>
                                </tr>
                            ) : (
                                skill?.related_occupations?.map(occupation => (
                                <tr key={occupation.id}>
                                    <td>
                                        <Link to={`/talent-insights/groups/${occupation.isco_group}/${occupation.id}`}>
                                            {occupation.name}
                                        </Link>
                                    </td>
                                    <td>
                                        {occupation.isco_group}
                                    </td>
                                    <td>
                                        {occupation.relation_type}
                                    </td>
                                </tr>
                                ))
                            )}
                            </tbody>
                        </Table>

                    </div>
                </Col>
            </Row>
            </div>
        </Container>
    );
};
export default Skill;