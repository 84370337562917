import React, { Component, useState, useEffect } from "react";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link } from 'react-router-dom';

import axios from "axios";
import { 
    ORDER_COUNT_MONTH_API_URL, 
    ORDER_COUNT_TODAY_API_URL 
} from "../../../constants";

import DashboardBox from "../../Global/Box";

const BoxOrderCount = (props) => {
    const token = localStorage.getItem('accessToken');
    let [ordersCreatedThisMonth, setOrdersCreatedThisMonth] = useState();
    let [ordersCreatedToday, setOrdersCreatedToday] = useState();

    const getOrdersCreatedThisMonth = () => {
        if (props.time === 'this-month') {
            axios.get(ORDER_COUNT_MONTH_API_URL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(res => setOrdersCreatedThisMonth(res.data));
        }
    };

    const getOrdersCreatedToday = () => {
        if (props.time === 'today') {
            axios.get(ORDER_COUNT_TODAY_API_URL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(res => setOrdersCreatedToday(res.data));
        }
    };

    useEffect(() => getOrdersCreatedThisMonth(), []);
    useEffect(() => getOrdersCreatedToday(), []);    

    return(
        <>
        {props.time == 'this-month' ? (
        <DashboardBox
            highlight={props.highlight}
            title={'Orders This Month'}
            value={ordersCreatedThisMonth}
            description={'Count of orders in created this month'}
        />
        ) : ''}
        {props.time == 'today' ? (
        <DashboardBox
            highlight={props.highlight}
            title={'Orders Today'}
            value={ordersCreatedToday}
            description={'Count of orders in created today'}
        />
        ) : ''}
        </>
    );
}

export default BoxOrderCount;
