import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from "reactstrap";

// import AuthService from './Auth';
import AuthContext from '../context/AuthContext';
import GoogleAuth from './GoogleAuth';

const Login = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login, currentUser } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.user) {
            // navigate('/dashboard');  // Redirect if user is already logged in
            window.location.href = '/dashboard';  
        }
    }, [currentUser, navigate]);
    
    return (
        <Container className="Main MainAdminFullWidth MainWithHero">
            <div className="Centralizer White">
                <Row>
                    <Col sm="4"></Col>
                    <Col sm="4">
                        <Row>
                            <Col className="GoogleLogin">
                                <img src="/img/jobibrains.png" className="LoginLogo"/>
                                <GoogleAuth />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="4">
                    </Col>
                </Row> 
            </div>
        </Container>
    );
};

export default Login;
