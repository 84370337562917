import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { Skeleton } from 'primereact/skeleton';

import SalesHeader from "./Header";


const SalesLeaderboard = (props) => {
    let campaigns = props.campaigns;

    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer">
            <SalesHeader
                current_user={props.current_user}
            />
            <Row>
                <Col>
                    <h2>
                        Sales Leaderboard
                    </h2>
                </Col>
            </Row>
        </div>
        <div className="Content">
            <Row>
                <Col>
                    <div className="TableContainer">
                        <Table className="WhiteTable AdminAnalysisTable">
                            <thead>
                            <tr>
                                <th>Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!campaigns || campaigns?.length <= 0 ? (
                                <tr>
                                    <td colSpan="4">
                                        {!campaigns <= 0 ? (
                                                <Skeleton className="mb-2"></Skeleton>                    
                                            ) : (
                                                <b>Ops, no sales here yet</b>
                                            )}                        
                                    </td>
                                </tr>
                            ) : (
                                campaigns?.map(campaign => (
                                <tr key={campaign.id}>
                                    <td width="50%">
                                        Name
                                    </td>
                                </tr>
                                ))
                            )}
                            </tbody>
                        </Table>
                    </div>

                </Col>
            </Row>
        </div>
      </Container>
    );
}

export default SalesLeaderboard;
