import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import Moment from 'react-moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faExternalLink, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Skeleton } from 'primereact/skeleton';


const CampaignGrid = (props) => {
    let campaigns = props.campaigns;

    return (
    <Row>
        {!campaigns || campaigns?.length <= 0 ? (
            <>
            {!campaigns <= 0 ? (
                <Skeleton className="mb-2"></Skeleton>                    
            ) : (
                <>
                <b>Ops, no campaigns here yet</b>
                </>
            )}                        
            </>
        ) : (
            campaigns?.map(campaign => (
            <Col sm="2" key={campaign.job}>
                <Link to={"/campaigns/campaigns/campaign/" + campaign.id}>
                    <div className="CampaignBox">
                        <span className="CampaignCandidateCount">
                            {campaign.candidate_count}
                        </span>
                        <div className="Square">
                            <img src={"https://storage.googleapis.com/jobillaweb-production-uploads/media/" + campaign.media_url} className="img-fluid facebook-ad-image" />
                        </div>
                        <div className="CampaignBoxContent">
                            <div className="CampaignBoxTitle">
                                    <h4>
                                        {campaign.job_name}
                                    </h4>
                            </div>
                            <div className="CampaignBoxDates">
                                <Moment format="DD/MM/YYYY" date={campaign.starts_at} />
                                - 
                                <Moment format="DD/MM/YYYY" date={campaign.ends_at} />
                            </div>
                        </div>
                    </div>
                </Link>
            </Col>
            ))
        )}
    </Row>
    );
}

export default CampaignGrid;
