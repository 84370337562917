import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Row, Table, Card } from "reactstrap";

import { Chart } from 'primereact/chart';

import axios from "axios";
import { GROUPS_API_URL } from "../../constants";


const Guarantee = (props) => {
    const token = localStorage.getItem('accessToken');
    let [prediction, setPrediction] = useState();
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    let [data, setData] = useState();
    let [dataLabels, setDataLabels] = useState(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']);
    let [dataValues, setDataValues] = useState();
    const [error, setError] = useState(null);

    let url = window.location.pathname
    let url_components = url.split('/')
    let group_id = url_components[3]

    const getPrediction = id => {
        let complete_url = GROUPS_API_URL + group_id + '/predict'
        axios.get(complete_url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setPrediction(response.data)
        });
    };

    useEffect(() => {
        if (dataLabels && dataValues) {
            const documentStyle = getComputedStyle(document.documentElement);
            const data = {
                labels: [dataLabels],
                datasets: [
                    {
                        data: dataValues,
                        backgroundColor: ['#9a77ca', '#7854ac'],
                        hoverBackgroundColor: ['#7854ac', '#6330ae']
                    }
                ]
            };

            const options = {
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [dataLabels, dataValues]);

    useEffect(() => getPrediction(), []);

    return (
    <Container className="GuaranteeContainer">
        {prediction?.relevant_recruitments?.relevant_recruitment_count > 0 ? (
        <Row>
            <Col sm="12" className="WebsiteServiceBox GuaranteeContainerBox">
                <Row>
                    <Col sm="6">
                        <div className="GuaranteeDataUnit">
                            <span className="GuaranteeDataTitle">Median Candidates</span>
                            <span className="GuaranteeDataHighlight">{prediction?.deciles[5]}</span>
                        </div>
                        <div className="GuaranteeDataUnit">
                            <span className="GuaranteeDataTitle">Average Candidates</span>
                            <span className="GuaranteeDataHighlight">{prediction?.relevant_recruitments?.relevant_average_candidate_count}</span>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div className="GuaranteeDataUnit">
                            <span className="GuaranteeDataTitle">50% of campaigns delivers between</span>
                            <span className="GuaranteeDataHighlight">{prediction?.normal_distribution[0]} - {prediction?.normal_distribution[1]}</span>
                        </div>
                        <div className="GuaranteeDataUnit">
                            <span className="GuaranteeDataTitle">Based on Recruitment Campaigns</span>
                            <span className="GuaranteeDataHighlight">{prediction?.relevant_recruitments?.relevant_recruitment_count}</span>
                        </div>
                    </Col>
                    {/* <Col sm="3">
                        <div className={prediction?.guarantee?.guarantee == true ? "GuaranteeBox GuaranteeBoxMargin" : "GuaranteeBox GuaranteeBoxNoGuarantee GuaranteeBoxMargin"}>
                            {prediction?.guarantee?.guaranteed_count > 0 ? (
                            <span className="Highlight">
                                {prediction?.guarantee?.guaranteed_count}
                            </span>) : ('')}
                            <h2>
                            </h2>
                            <span>
                            {prediction?.guarantee?.guarantee == true ? ('candidates guaranteed with Digital Headhunting Smart based on ' + prediction?.guarantee?.guarantee_basis): ('Sorry, no guarantee available')}
                            </span>
                        </div>
                    </Col> */}
                </Row>
            </Col>
        </Row>
        ) : ('')}
        <Row>
            {prediction?.product?.suggested_product_variation == 'Lite' ? ('') : ('')}
            <Col sm="4">
                <div className={prediction?.product?.suggested_product_variation == 'Lite' ? ("ProductVariationBox Suggested") : prediction?.product?.allowed_product_variations?.Lite ? ("ProductVariationBox Available") : ("ProductVariationBox Disabled")}>
                    {/* <span className="ProductVariationTitle">easy target group</span> */}
                    <h4>Jobilla Target &trade; <br/><span>Lite</span></h4>
                    <span className="ProductVariationAvailability">{prediction?.product?.suggested_product_variation == 'Lite' ? ('Suggested') : prediction?.product?.allowed_product_variations?.Lite ? ('Available') : ('Not Available')}</span>             
                </div>
            </Col>
            <Col sm="4">
            <div className={prediction?.product?.suggested_product_variation == 'Standard' ? ("ProductVariationBox Suggested") : prediction?.product?.allowed_product_variations?.Standard ? ("ProductVariationBox Available") : ("ProductVariationBox Disabled")}>
            {/* <span className="ProductVariationTitle">most target groups</span> */}
                    <h4>Jobilla <br/><span>Target &trade;</span></h4>   
                    <span className="ProductVariationAvailability">{prediction?.product?.suggested_product_variation == 'Standard' ? ('Suggested') : prediction?.product?.allowed_product_variations?.Standard ? ('Available') : ('Not Available')}</span>             
                </div>
            </Col>
            <Col sm="4">
                <div className={prediction?.product?.suggested_product_variation == 'Power' ? ("ProductVariationBox Suggested") : prediction?.product?.allowed_product_variations?.Power ? ("ProductVariationBox Available") : ("ProductVariationBox Disabled")}>
                    {/* <span className="ProductVariationTitle">difficult target groups</span> */}
                    <h4>Jobilla Target &trade; <br/><span>Pro</span></h4>
                    <span className="ProductVariationAvailability">{prediction?.product?.suggested_product_variation == 'Power' ? ('Suggested') : prediction?.product?.allowed_product_variations?.Power ? ('Available') : ('Not Available')}</span>             
                </div>
            </Col>
        </Row>
    </Container>
);
}

export default Guarantee;
