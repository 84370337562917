// React
import React, { Component, Fragment, useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useLocation, useNavigate, useMatch, withRouter } from "react-router-dom";

// REST & Authentication
import axios from "axios";
import { USERS_API_URL, CURRENT_COMPANY_API_URL, CURRENT_USER_API_URL } from "./constants";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import AuthContext from "./context/AuthContext";

// Global Components
import TopBar from "./functions/Global/TopBar";
import Meridien from "./functions/Global/Meridien";
import Login from "./utils/Login";
import Register from "./utils/Register";
import Home from "./functions/Home";
import OccupationGroups from "./functions/TalentInsights/Groups";
import OccupationGroup from "./functions/TalentInsights/Group";
import CareerClusters from "./functions/TalentInsights/Clusters";
import Occupations from "./functions/TalentInsights/Occupations";
import Occupation from "./functions/TalentInsights/Occupation";
import CareerCluster from "./functions/TalentInsights/Cluster";
import CareerPathways from "./functions/TalentInsights/Pathways";
import CareerPathway from "./functions/TalentInsights/Pathway";
import Skills from "./functions/TalentInsights/Skills";
import Skill from "./functions/TalentInsights/Skill";
import Logout from "./utils/Logout";
import CustomersDashboard from "./functions/Customers/Dashboard";
import Sales from "./functions/Growth/Sales";
import Campaigns from "./functions/Campaigns/Campaigns";
import Users from "./functions/Customers/Users";
import Countries from "./functions/Campaigns/Countries";
import Cities from "./functions/Campaigns/Cities";
import SalesLeaderboard from "./functions/Growth/Leaderboard";
import SalesPeople from "./functions/Growth/People";
import Dashboard from "./functions/Global/Dashboard";
import GrowthDashboard from "./functions/Growth/Dashboard";
import Candidates from "./functions/Campaigns/Candidates";
import CandidateAcquisitionDashboard from "./functions/Campaigns/Dashboard";
import CampaignPerformance from "./functions/Campaigns/Performance";
import Companies from "./functions/Customers/Companies";
import HiringDashboard from "./functions/Hiring/Dashboard";
import Hires from "./functions/Hiring/Hires";
import ATSConnections from "./functions/Hiring/Connections";
import CustomerMeetings from "./functions/Customers/Meetings";
import CustomerFeedback from "./functions/Customers/Feedback";
import AdminUsers from "./functions/Admin/Users";
import AdminUserAnalytics from "./functions/Admin/UserAnalytics";
import Consumption from "./functions/Growth/Consumption";
import CandidateFunnelAnalytics from "./functions/Campaigns/Funnel";
import TalentInsightsRegions from "./functions/TalentInsights/Regions";
import TalentInsightRegion from "./functions/TalentInsights/Region";
import FinanceDashboard from "./functions/Finance/Dashboard";
import Invoicing from "./functions/Finance/Invoicing";
import GrowthProducts from "./functions/Growth/Products";
import NoPermissions from "./utils/NoPermissions";
import Sources from "./functions/Campaigns/Sources";
import Campaign from "./functions/Campaigns/Campaign";
import AISuiteDashboard from "./functions/Incubator/Dashboard";
import WelcomeAI from "./functions/Incubator/Welcome";
import AdImageAI from "./functions/Incubator/AdImage";
import QuestionnaireAI from "./functions/Incubator/Questionnaire";
import TargetingInterestAI from "./functions/Incubator/TargetingInterest";
import PredictAI from "./functions/Incubator/Predict";
import AttributeAI from "./functions/Incubator/Attribute";
import CombinedAIIncubator from "./functions/Incubator/CombinedAI";
import AdSpending from "./functions/Campaigns/AdSpending";
import CampaignSuccess from "./functions/Campaigns/CampaignSuccess";
import Orders from "./functions/Growth/Orders";

// Pages

const App = (props) => {
    const token = localStorage.getItem('accessToken');
    const { user } = useContext(AuthContext);
    let [mode, setMode] = useState('app');
    let [current_user, setCurrentUser] = useState(null);
    let [current_company, setCurrentCompany] = useState(null);
    let [header_link_color, setHeaderLinkColor] = useState('White');
    let [path, setPath] = useState('/');
    let [redirect, setRedirect] = useState(false);
    let [single_recruitment_id, setSingleRecruitmentId] = useState(1);
    let [adminActive, setAdminActive] = useState(false);
    let [oldColors, setOldColors] = useState('light');
    let [darkMode, setDarkMode] = useState('light');
    const languages = [
        { name: 'English', code: 'en' },
        { name: 'Finnish', code: 'fi' },
        { name: 'German', code: 'de' },
    ];
    const countries = [
        {"name": "Austria", "value": "AT"},
        {"name": "Belgium", "value": "BE"},
        {"name": "Bulgaria", "value": "BG"},
        {"name": "Croatia", "value": "HR"},
        {"name": "Cyprus", "value": "CY"},
        {"name": "Czech Republic", "value": "CZ"},
        {"name": "Denmark", "value": "DK"},
        {"name": "Estonia", "value": "EE"},
        {"name": "Finland", "value": "FI"},
        {"name": "France", "value": "FR"},
        {"name": "Germany", "value": "DE"},
        {"name": "Greece", "value": "GR"},
        {"name": "Hungary", "value": "HU"},
        {"name": "Ireland", "value": "IE"},
        {"name": "Italy", "value": "IT"},
        {"name": "Latvia", "value": "LV"},
        {"name": "Lithuania", "value": "LT"},
        {"name": "Luxembourg", "value": "LU"},
        {"name": "Malta", "value": "MT"},
        {"name": "Netherlands", "value": "NL"},
        {"name": "Norway", "value": "NO"},
        {"name": "Poland", "value": "PL"},
        {"name": "Portugal", "value": "PT"},
        {"name": "Romania", "value": "RO"},
        {"name": "Slovakia", "value": "SK"},
        {"name": "Slovenia", "value": "SI"},
        {"name": "Spain", "value": "ES"},
        {"name": "Sweden", "value": "SE"}
    ];    

    const handleMode = (mode) => {
        setMode(mode);
    };

    const changeRecruitment = (recruitment_id) => {
        setSingleRecruitmentId(recruitment_id);
    };

    const changeCurrentCompany = (new_current_company_id) => {
        let current_company_data = {
            'pk': current_user?.pk,
            'name': current_user?.name,
            'user': current_user?.user,
            'current_company': new_current_company_id
        };
        // e.preventDefault();
        axios.put(USERS_API_URL + current_user?.pk, current_company_data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(() => {
        });
        window.location.href = '/';
    };

    const changeCurrentLanguage = (language_shortcode) => {
        let language_data = {
            'pk': current_user?.pk,
            'current_language_shortcode': language_shortcode,
        };
        // e.preventDefault();
        axios.put(USERS_API_URL + current_user?.pk, language_data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(() => {
            window.location.reload();
        });
    };

    const changeCurrentCountry = (country_shortcode) => {
        let country_data = {
            'pk': current_user?.pk,
            'current_country_shortcode': country_shortcode,
        };
        let complete_url = USERS_API_URL + current_user?.pk;
        // e.preventDefault();
        if (current_user) {
            axios.put(complete_url, country_data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(() => {
                window.location.reload();
            });    
        } else {
            console.log('Not logged in')
            window.location.href = '/logout';  
        }
    };

    const getCurrentUser = () => {
        axios.get(CURRENT_USER_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCurrentUser(res.data));
    };

    const getCurrentCompany = () => {
        axios.get(CURRENT_COMPANY_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCurrentCompany(res.data));
    };

    const checkAdmin = (url) => {
        if (url.includes('admin')) {
            setAdminActive(true);
        }    
    };

    const toggleOldColors = () => {
        if (oldColors == 'light') {
            setOldColors('dark');
        } else {
            setOldColors('light');
        }
    };

    const toggleDarkMode = () => {
        if (darkMode == 'light') {
            setDarkMode('dark');
        } else {
            setDarkMode('light');
        }
    };

    useEffect(() => {
        if (user !== null) {
            getCurrentUser()}
        }
    , [user]);
    // useEffect(() => getCurrentCompany(), []);

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    return (
        <Fragment>
            <div className={darkMode === "dark" ? "AppContainer DarkMode" : "AppContainer Light"}>
                    <TopBar 
                        mode={mode} 
                        user={user}
                        current_user={current_user} 
                        current_user_name={current_user?.name} 
                        current_company_name={current_company?.name} 
                        current_company_id={current_company?.id} 
                        single_recruitment_id={single_recruitment_id}
                        header_link_color={header_link_color}
                        changeCurrentCompany={changeCurrentCompany}
                        adminActive={adminActive}
                        checkAdmin={checkAdmin}
                        oldColors={oldColors}
                        darkMode={darkMode}
                        languages={languages}
                        countries={countries}
                        changeCurrentLanguage={changeCurrentLanguage}
                        changeCurrentCountry={changeCurrentCountry}
                        toggleDarkMode={toggleDarkMode}
                    />
                    {user ? (
                    <Meridien 
                        current_user={current_user} 
                    />
                    ) : ('')}
                    <Routes>
                        <Route path="/" element={<Login user={user} />} />
                        <Route path="/login" element={<Login user={user} />} />
                        <Route path="/logout" element={ <PrivateRoute><Logout /></PrivateRoute>}/>
                        <Route path="/no-permissions" element={<NoPermissions user={user} />} />

                        {/* <Route path="/" element={<PrivateRoute><Dashboard current_user={current_user} /></PrivateRoute>} /> */}
                        <Route path="/dashboard" element={<PrivateRoute><Dashboard current_user={current_user} /></PrivateRoute>} />

                        {/* CUSTOMERS */}
                        <Route path="/customers" element={<PrivateRoute><CustomersDashboard current_user={current_user} /></PrivateRoute>} />
                        <Route path="/customers/companies" element={<PrivateRoute><Companies current_user={current_user} /></PrivateRoute>} />
                        <Route path="/customers/users" element={<PrivateRoute><Users current_user={current_user} /></PrivateRoute>} />
                        <Route path="/customers/meetings" element={<PrivateRoute><CustomerMeetings current_user={current_user} /></PrivateRoute>} />
                        <Route path="/customers/feedback" element={<PrivateRoute><CustomerFeedback current_user={current_user} /></PrivateRoute>} />

                        {/* GROWTH */}
                        <Route path="/growth" element={<PrivateRoute><GrowthDashboard current_user={current_user} /></PrivateRoute>} />
                        <Route path="/growth/sales-leaderboard" element={<PrivateRoute><SalesLeaderboard current_user={current_user} /></PrivateRoute>} />
                        <Route path="/growth/salespeople" element={<PrivateRoute><SalesPeople current_user={current_user} /></PrivateRoute>} />
                        <Route path="/growth/sales" element={<PrivateRoute><Sales current_user={current_user} /></PrivateRoute>} />
                        <Route path="/growth/orders" element={<PrivateRoute><Orders current_user={current_user} /></PrivateRoute>} />
                        <Route path="/growth/consumption" element={<PrivateRoute><Consumption current_user={current_user} /></PrivateRoute>} />
                        <Route path="/growth/products" element={<PrivateRoute><GrowthProducts current_user={current_user} /></PrivateRoute>} />

                        {/* CAMPAIGN PERFORMANCE */}
                        <Route path="/campaigns" element={<PrivateRoute><CandidateAcquisitionDashboard current_user={current_user} /></PrivateRoute>} />
                        <Route path="/campaigns/campaigns" element={<PrivateRoute><Campaigns current_user={current_user} /></PrivateRoute>} />
                        <Route path="/campaigns/campaigns/campaign/:id" element={<PrivateRoute><Campaign current_user={current_user} /></PrivateRoute>} />
                        <Route path="/campaigns/campaign-success" element={<PrivateRoute><CampaignSuccess current_user={current_user} /></PrivateRoute>} />
                        <Route path="/campaigns/performance" element={<PrivateRoute><CampaignPerformance current_user={current_user} /></PrivateRoute>} />
                        <Route path="/campaigns/funnel-analytics" element={<PrivateRoute><CandidateFunnelAnalytics current_user={current_user} /></PrivateRoute>} />
                        <Route path="/campaigns/countries" element={<PrivateRoute><Countries current_user={current_user} /></PrivateRoute>} />
                        <Route path="/campaigns/cities" element={<PrivateRoute><Cities current_user={current_user} /></PrivateRoute>} />
                        <Route path="/campaigns/candidates" element={<PrivateRoute><Candidates current_user={current_user} /></PrivateRoute>} />
                        <Route path="/campaigns/sources" element={<PrivateRoute><Sources current_user={current_user} /></PrivateRoute>} />
                        <Route path="/campaigns/ad-spending" element={<PrivateRoute><AdSpending current_user={current_user} /></PrivateRoute>} />

                        {/* HIRING */}
                        <Route path="/hire" element={<PrivateRoute><HiringDashboard current_user={current_user} /></PrivateRoute>} />
                        <Route path="/hire/hires" element={<PrivateRoute><Hires current_user={current_user} /></PrivateRoute>} />
                        <Route path="/hire/connections" element={<PrivateRoute><ATSConnections current_user={current_user} /></PrivateRoute>} />

                        {/* TALENT INSIGHTS */}
                        <Route path="/talent-insights" element={<PrivateRoute><Occupations current_user={current_user} /></PrivateRoute>} />
                        <Route path="/talent-insights/occupations/:id" element={<PrivateRoute><Occupation current_user={current_user} /></PrivateRoute>} />
                        <Route path="/talent-insights/groups" element={<PrivateRoute><OccupationGroups current_user={current_user} /></PrivateRoute>} />
                        <Route path="/talent-insights/groups/:id" element={<PrivateRoute><OccupationGroup current_user={current_user} /></PrivateRoute>} />
                        <Route path="/talent-insights/groups/:id/:occupation_id" element={<PrivateRoute><OccupationGroup current_user={current_user} /></PrivateRoute>} />
                        <Route path="/talent-insights/clusters" element={<PrivateRoute><CareerClusters current_user={current_user} /></PrivateRoute>} />
                        <Route path="/talent-insights/clusters/:id" element={<PrivateRoute><CareerCluster current_user={current_user} /></PrivateRoute>} />
                        <Route path="/talent-insights/pathways" element={<PrivateRoute><CareerPathways current_user={current_user} /></PrivateRoute>} />
                        <Route path="/talent-insights/pathways/:id" element={<PrivateRoute><CareerPathway current_user={current_user} /></PrivateRoute>} />
                        <Route path="/talent-insights/skills" element={<PrivateRoute><Skills current_user={current_user} /></PrivateRoute>} />
                        <Route path="/talent-insights/skills/:id" element={<PrivateRoute><Skill current_user={current_user} /></PrivateRoute>} />
                        <Route path="/talent-insights/regions" element={<PrivateRoute><TalentInsightsRegions current_user={current_user} /></PrivateRoute>} />
                        <Route path="/talent-insights/regions/:id" element={<PrivateRoute><TalentInsightRegion current_user={current_user} /></PrivateRoute>} />

                        {/* FINANCE */}
                        <Route path="/finance" element={<PrivateRoute><FinanceDashboard current_user={current_user} /></PrivateRoute>} />
                        <Route path="/finance/invoicing" element={<PrivateRoute><Invoicing current_user={current_user} /></PrivateRoute>} />

                        {/* AI SUITE */}
                        <Route path="/ai-incubator" element={<PrivateRoute><AISuiteDashboard current_user={current_user} /></PrivateRoute>} />
                        <Route path="/ai-incubator/ad-image-ai" element={<PrivateRoute><AdImageAI current_user={current_user} /></PrivateRoute>} />
                        <Route path="/ai-incubator/combined-ai" element={<PrivateRoute><CombinedAIIncubator current_user={current_user} /></PrivateRoute>} />
                        {/* <Route path="/ai-incubator/welcome-ai" element={<PrivateRoute><WelcomeAI current_user={current_user} /></PrivateRoute>} />
                        <Route path="/ai-incubator/questionnaire-ai" element={<PrivateRoute><QuestionnaireAI current_user={current_user} /></PrivateRoute>} />
                        <Route path="/ai-incubator/attribute-ai" element={<PrivateRoute><AttributeAI current_user={current_user} /></PrivateRoute>} />
                        <Route path="/ai-incubator/targeting-interest-ai" element={<PrivateRoute><TargetingInterestAI current_user={current_user} /></PrivateRoute>} /> */}
                        <Route path="/ai-incubator/predict-ai" element={<PrivateRoute><PredictAI current_user={current_user} /></PrivateRoute>} />

                        {/* ADMIN */}
                        <Route path="/admin/users" element={<PrivateRoute><AdminUsers current_user={current_user} /></PrivateRoute>} />
                        <Route path="/admin/user-analytics" element={<PrivateRoute><AdminUserAnalytics current_user={current_user} /></PrivateRoute>} />



                        {/* <Route path="/login" element={<Login />}  />
                        <Route path="/register"  element={<Register />} /> */}

                    </Routes>

        </div>
        </Fragment>
    );
}

export default App;
