import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from "reactstrap";

// import AuthService from './Auth';
import AuthContext from '../context/AuthContext';
import GoogleAuth from './GoogleAuth';

const NoPermissions = (props) => {
    
    return (
        <Container className="Main MainAdminFullWidth MainWithHero">
            <div className="Centralizer White">
                <Row>
                    <Col sm="4"></Col>
                    <Col sm="4">
                        <Row>
                            You don't have permissions to access the app. Please contact the app admin if you think you should have the right to access it.
                        </Row>
                    </Col>
                    <Col sm="4">
                    </Col>
                </Row> 
            </div>
        </Container>
    );
};

export default NoPermissions;
