import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { CLUSTERS_API_URL } from "../../constants";
import AdminClusterList from "./ClusterList";
import AdminClusterCards from "./ClusterCards";
import TalentInsightsHeader from "./Header";
import CareerClusterPie from "./ClusterPie";


const CareerClusters = (props) => {
    const token = localStorage.getItem('accessToken');
    let [clusters, setClusters] = useState([]);
    let [cluster_search, setClusterSearch] = useState('');

    const handleClusterSearch = (event) => {
        setClusterSearch(event.target.value);
        getFilteredClusters(event.target.value);
    };

    const getClusters = () => {
        axios.get(CLUSTERS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setClusters(res.data));
    };

    const getFilteredClusters = (cluster_search) => {
        axios.get(CLUSTERS_API_URL + '?name=' + cluster_search, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setClusters(res.data));
    };

    useEffect(() => getClusters(), []);

    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer White">
        <TalentInsightsHeader 
            current_user={props.current_user}
        />
        <Row>
            <Col>
                <div className="Box DarkPurple TalentInsightsBox">
                    <h2>
                        {props.current_user?.current_language_code == 'en' ? 'Career Clusters' : ''}
                        {props.current_user?.current_language_code == 'fi' ? 'Urakategoriat' : ''}
                        {props.current_user?.current_language_code == 'de' ? 'Career Clusters' : ''}                        
                    </h2>
                    <div className="RecruitmentSearch AdminFilter">
                        <input className="TextInput" placeholder="Search" value={cluster_search} onChange={handleClusterSearch}/>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
          <Col>
            <AdminClusterCards 
                clusters={clusters}
                cluster_search={cluster_search}
                current_user={props.current_user}
            />
            {/* <AdminClusterList
                clusters={clusters}
                cluster_search={cluster_search}
            /> */}
          </Col>
          {/* <Col sm="4">
                <CareerClusterPie 
                    clusters={clusters}
                />
          </Col> */}
        </Row>
        </div>
      </Container>
    );
}

export default CareerClusters;
