import { faDashboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { NumericFormat } from 'react-number-format';

const CampaignAd = (props) => {

    let ad = props.ad;
    return (
        <div key={ad?.id}>
            <h4>{ad?.title}</h4>
            <p>{ad?.intro}</p>
            <div className="CampaignBox">
                <div className="Square">
                    <img src={"https://storage.googleapis.com/jobillaweb-production-uploads/media/" + ad.media_url} className="img-fluid facebook-ad-image" />
                </div>
            </div>
            <p>{ad?.description}</p>
            <a target="_blank" src={ad?.url} className="btn btn-primary">Funnel</a>
        </div>
);
}

export default CampaignAd;
