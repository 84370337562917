import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import Moment from 'react-moment';

import { Knob } from 'primereact/knob';
import { ProgressBar } from 'primereact/progressbar';

import axios from "axios";
import { GROUPS_API_URL, PATHWAYS_API_URL } from "../../constants";
import TalentInsightsHeader from "./Header";
import AdminGroupList from "./GroupList";

const TalentInsightRegion = (props) => {
    const token = localStorage.getItem('accessToken');
    let [region, setRegion] = useState();

    let url = window.location.pathname
    let url_components = url.split('/')
    let pathway_id = url_components[3]

    // const getPathway = id => {
    //     axios.get(PATHWAYS_API_URL + pathway_id).then((response) => {
    //         setPathway(response.data)
    //     });
    // };

    // const getFilteredGroups = () => {
    //     let complete_url = GROUPS_API_URL + '?pathway=' + pathway_id
    //     axios.get(complete_url).then(res => setGroups(res.data));    
    // };


    // useEffect(() => getPathway(), []);
    // useEffect(() => getFilteredGroups(), []);

    return (
        <Container className="Main MainAdminFullWidth MainWithHero">
        <div className="Centralizer White">
        <TalentInsightsHeader 
                current_user={props.current_user}
            />
            <Row>
                <Col>
                    <div className="Box DarkPurple TalentInsightsBox">
                        <h2>
                            Region: {region?.label}
                        </h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4>Target Groups by Size</h4>
                </Col>
            </Row>
          </div>
        </Container>
    );
};
export default TalentInsightRegion;