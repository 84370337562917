import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import Moment from 'react-moment';

import { Knob } from 'primereact/knob';
import { ProgressBar } from 'primereact/progressbar';

import axios from "axios";
import { CLUSTERS_API_URL, GROUPS_API_URL, PATHWAYS_API_URL } from "../../constants";
import AdminGroupList from "./GroupList";
import TalentInsightsHeader from "./Header";
import AdminClusterCards from "./ClusterCards";
import AdminPathwayList from "./PathwayList";

const CareerCluster = (props) => {
    const token = localStorage.getItem('accessToken');
    let [cluster, setCluster] = useState();
    let [groups, setGroups] = useState([]);
    let [pathways, setPathways] = useState([]);

    let url = window.location.pathname
    let url_components = url.split('/')
    let cluster_id = url_components[3]

    const getCluster = id => {
        axios.get(CLUSTERS_API_URL + cluster_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setCluster(response.data)
        });
    };

    const getFilteredGroups = () => {
        let complete_url = GROUPS_API_URL + '?category=' + cluster_id
        axios.get(complete_url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setGroups(res.data));
    };

    const getFilteredPathways = () => {
        let complete_url = PATHWAYS_API_URL + '?category=' + cluster_id
        axios.get(complete_url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setPathways(res.data));
        console.log(complete_url)
    };

    useEffect(() => getCluster(), []);
    useEffect(() => getFilteredGroups(), []);
    useEffect(() => getFilteredPathways(), []);

    return (
        <Container className="Main MainAdminFullWidth MainWithHero">
        <div className="Centralizer White">
        <TalentInsightsHeader 
                current_user={props.current_user}
            />
            <Row>
                <Col>
                    <div className="Box DarkPurple TalentInsightsBox">
                        <h4>Cluster: {cluster?.pk}</h4>
                        <h2>
                            {props.current_user?.current_language_code == 'en' ? `${cluster?.name}` : ''}
                            {props.current_user?.current_language_code == 'fi' ? `${cluster?.name_fi}` : ''}
                            {props.current_user?.current_language_code == 'de' ? `${cluster?.name_de}` : ''}
                        </h2>
                    </div>
                </Col>
            </Row>
            <Row>
            <Col>
                <h3>Related Career Pathways</h3>
                <AdminPathwayList
                    pathways={pathways}
                    current_user={props.current_user}
                />
            </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Related Occupation Groups</h3>
                    <AdminGroupList
                        groups={groups}
                        current_user={props.current_user}
                    />
                </Col>
            </Row>
            </div>
        </Container>
    );
};
export default CareerCluster;