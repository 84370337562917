import axios from 'axios';
const API_URL = process.env.REACT_APP_LOCAL === 'true' ? 'http://localhost:5555/api' : 'https://jobilla-brains.rede.fi/api';

const axiosInstance = axios.create({
    baseURL: API_URL, 
});

axiosInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = localStorage.getItem('refreshToken');
                const response = await axios.post('/api/token/refresh/', {
                    refresh: refreshToken,
                });
                const { access } = response.data;
                localStorage.setItem('accessToken', access);
                originalRequest.headers['Authorization'] = `Bearer ${access}`;
                return axiosInstance(originalRequest);
            } catch (err) {
                console.error('Error refreshing token', err);
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
