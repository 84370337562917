import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table } from "reactstrap";
import { NumericFormat } from 'react-number-format';

import { Chart } from 'primereact/chart';

import axios from "axios";
import { GIANT_JOB_BENEFITS_API_URL } from "../../../constants";


const GiantJobBenefits = (props) => {
    const token = localStorage.getItem('accessToken');
    let [jobBenefits, setJobBenefits] = useState();

    const getJobBenefits = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
        axios.get(GIANT_JOB_BENEFITS_API_URL + group_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setJobBenefits(response.data);
        });
    };

    useEffect(() => getJobBenefits(), []);

    return (
        <div className="TalentInsightsContentBox">
            <h4>Job Benefits</h4>
            <Table className="">
                <thead>
                    <tr>
                        <th>Label</th>
                        <th align="right">%</th>
                    </tr>
                </thead>
                <tbody>
                {!jobBenefits || jobBenefits?.length <= 0 ? (
                    <tr>
                        <td colSpan="2">
                            <b>Ops, no benefits data here yet</b>
                        </td>
                    </tr>
                ) : (
                    jobBenefits?.map(benefit => (
                    <tr key={benefit.id}>
                        <td width="50%">
                            {benefit.label}
                        </td>
                        <td width="50%" align="right">
                            <NumericFormat
                                value={benefit.value * 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={1}
                            /> %
                        </td>
                    </tr>
                    ))
                )}
                </tbody>
            </Table>
            <span className="TalentInsightsBoxDescription">
                The most important job benifits for the {props.group?.name} (besides salary).
                Data source Intelligence Group.
            </span>
        </div>
);
}

export default GiantJobBenefits;
