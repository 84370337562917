import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import { SKILLS_API_URL } from "../../constants";
import AdminSkillList from "./SkillsList";
import TalentInsightsHeader from "./Header";


const Skills = (props) => {
    const token = localStorage.getItem('accessToken');
    let [skills, setSkills] = useState([]);
    let [skill_search, setSkillSearch] = useState('');

    const handleSkillSearch = (event) => {
      setSkillSearch(event.target.value);
      getFiltereSkills(event.target.value);
    };
    // let [status_filter, setStatusFilter] = useState('');    

    // const handleStatusFilter = (status_filter) => {
    //     setStatusFilter(status_filter);
    //     getFilteredInvoices(status_filter);
    //     getFilteredInvoiceData(status_filter);
    // };

    const getSkills = () => {
        axios.get(SKILLS_API_URL).then(res => setSkills(res.data));
    };

    const getFiltereSkills = (skill_search) => {
        axios.get(SKILLS_API_URL + '?name=' + skill_search).then(res => setSkills(res.data));
    };

    useEffect(() => getSkills(), []);

    return (
      <Container className="Main MainAdminFullWidth MainWithHero">
        <div className="Centralizer">
        <TalentInsightsHeader 
            current_user={props.current_user}
        />
        <Row>
            <Col>
                <div className="Box DarkPurple TalentInsightsBox">
                  <h2>
                      {props.current_user?.current_language_code == 'en' ? 'Skills (ESCO)' : ''}
                      {props.current_user?.current_language_code == 'fi' ? 'Taidot (ESCO)' : ''}
                      {props.current_user?.current_language_code == 'de' ? 'Skills (ESCO)' : ''}                        
                  </h2>
                    <div className="RecruitmentSearch AdminFilter">
                      <input className="TextInput" placeholder="Search" value={skill_search} onChange={handleSkillSearch}/>
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
          <Col>
            <AdminSkillList
                skills={skills}
                skill_search={skill_search}
            />
          </Col>
        </Row>
        </div>
      </Container>
    );
}

export default Skills;
