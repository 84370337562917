import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Chart } from 'primereact/chart';

import axios from "axios";
import { GIANT_WORK_EXPERIENCE_API_URL } from "../../../constants";


const GiantWorkExperience = (props) => {
    const token = localStorage.getItem('accessToken');
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    let [workExperience, setWorkExperience] = useState();
    let [experienceLabels, setExperienceLabels] = useState();
    let [experienceValues, setExperienceValues] = useState();

    const getWorkExperience = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let group_id = url_components[3]
        axios.get(GIANT_WORK_EXPERIENCE_API_URL + group_id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setWorkExperience(response.data);
            setExperienceLabels(response.data.map(obj => obj.label));
            setExperienceValues(response.data.map(obj => obj.value * 100));
        });
    };

    useEffect(() => {
        if (experienceLabels && experienceValues) {
            const documentStyle = getComputedStyle(document.documentElement);
            const data = {
                labels: experienceLabels,
                datasets: [
                    {
                        data: experienceValues,
                        backgroundColor: ['#d401ab', '#03c7ff', '#006aff', '#710ce2'],
                        hoverBackgroundColor: ['#70005a', '#077b9b', '#02367f', '#4e089b']
                        // backgroundColor: ['#9a77ca', '#7854ac'],
                        // hoverBackgroundColor: ['#7854ac', '#6330ae']
                        // backgroundColor: [
                        //     documentStyle.getPropertyValue('--blue-500'), 
                        //     documentStyle.getPropertyValue('--yellow-500'), 
                        //     documentStyle.getPropertyValue('--green-500'), 
                        // ],
                        // hoverBackgroundColor: [
                        //     documentStyle.getPropertyValue('--blue-400'), 
                        //     documentStyle.getPropertyValue('--yellow-400'), 
                        //     documentStyle.getPropertyValue('--green-400'), 
                        // ]
                    }
                ]
            };
            const options = {
                plugins: {
                    legend: {
                        display: false // This will hide the label (legend)
                    }
                }
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [experienceLabels, experienceValues]);

    useEffect(() => getWorkExperience(), []);

    return (
        <div className="TalentInsightsContentBox">
            <h4>Work Experience</h4>
            {experienceLabels && experienceValues ? (
                <Chart type="bar" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
            ): (
                <></>
            )}
            <span className="TalentInsightsBoxDescription">
                The distribution of the {props.group?.name} by experience level. Three categories are distinguished based
                on the number of years of work experience: junior (0-5 years), medior (5-10 ) and senior (10 years or more). 
                Data source Intelligence Group.
            </span>
        </div>
);
}

export default GiantWorkExperience;
