import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import Moment from 'react-moment';

import axios from "axios";
import { OCCUPATIONS_API_URL } from "../../constants";
import TalentInsightsHeader from "./Header";

const Occupation = (props) => {
    const token = localStorage.getItem('accessToken');
    let [occupation, setOccupation] = useState();

    const getOccupation = id => {
        let url = window.location.pathname
        let url_components = url.split('/')
        let occupation_id = url_components[3]
        axios.get(OCCUPATIONS_API_URL + occupation_id).then((response) => {
            setOccupation(response.data)
        });
    };

    useEffect(() => getOccupation(), []);

    return (
        <Container className="Main MainAdminFullWidth MainWithHero">
        <div className="Centralizer White">
        <TalentInsightsHeader 
                current_user={props.current_user}
            />
            <Row>
                <Col>
                    <div className="Box DarkPurple">
                        <h2>Occupation: {occupation?.name}</h2>
                        <p>{occupation?.alt_labels}</p>
                        {occupation?.isco_group ? (
                        <Link to={`/talent-insights/groups/${occupation?.isco_group}`} className="btn btn-primary">
                            Group: {occupation?.group_name}
                        </Link>
                        ) : ('')}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Related Skills</h3>
                    <div className="TableContainer">
                        <Table className="WhiteTable AdminAnalysisTable">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Relation Type</th>
                                <th>Skill Type</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!occupation?.related_skills || occupation?.related_skills?.length <= 0 ? (
                                <tr>
                                <td colSpan="1">
                                    <b>Ops, no related skills here yet</b>
                                </td>
                                </tr>
                            ) : (
                                occupation?.related_skills?.map(skill => (
                                <tr key={skill.id}>
                                    <td width="5%">
                                        {skill.id}
                                    </td>
                                    <td>
                                        <Link to={`/talent-insights/skills/${skill.id}`}>
                                            {skill.name}
                                        </Link>
                                    </td>
                                    <td>
                                        {skill.relation_type}
                                    </td>
                                    <td>
                                        {skill.skill_type}
                                    </td>
                                </tr>
                                ))
                            )}
                            </tbody>
                        </Table>

                    </div>
                </Col>
            </Row>
            </div>
        </Container>
    );
};
export default Occupation;