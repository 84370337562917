import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import Moment from 'react-moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { Skeleton } from 'primereact/skeleton';

const AdminPathwayList = (props) => {
    let pathways = props.pathways;
    return (
    <>
      <div className="TableContainer">
        <Table className="WhiteTable AdminAnalysisTable">
            <thead>
            <tr>
                <th>Code</th>
                <th>Name</th>
                <th>Cluster</th>
                <th className="text-right">Past Campaigns</th>
            </tr>
            </thead>
            <tbody>
            {!pathways || pathways?.length <= 0 ? (
                <tr>
                    <td colSpan="4">
                        {!pathways <= 0 ? (
                                <Skeleton className="mb-2"></Skeleton>                    
                            ) : (
                                <b>Ops, no pathways here yet</b>
                            )}                        
                    </td>
                </tr>
            ) : (
                pathways?.map(pathway => (
                <tr key={pathway.id}>
                    <td width="5%">
                        {pathway.id}
                    </td>
                    <td width="45%">
                        <Link to={`/talent-insights/pathways/${pathway.id}`}>
                            {props.current_user?.current_language_code == 'en' ? `${pathway.name}` : ''}
                            {props.current_user?.current_language_code == 'fi' ? `${pathway?.name_fi}` : ''}
                            {props.current_user?.current_language_code == 'de' ? `${pathway?.name_de}` : ''}
                        </Link>
                    </td>
                    <td width="30%">
                        {props.current_user?.current_language_code == 'en' ? `${pathway.category_name?.name}` : ''}
                        {props.current_user?.current_language_code == 'fi' ? `${pathway.category_name?.name_fi}` : ''}
                        {props.current_user?.current_language_code == 'de' ? `${pathway.category_name?.name_de}` : ''}
                    </td>
                    <td width="20%" align="right">
                        {pathway.sample_job_count}
                    </td>
                </tr>
                ))
            )}
            </tbody>
        </Table>
      </div>
    </>
    );
}

export default AdminPathwayList;
